import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { StaffModel } from './staff.model';
import { HttpParamsUtilities } from '../../utilities/http-params-utilities/http-params-utilities';

export { StaffModel } from './staff.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  public constructor(private http: HttpClient) { }

  private staffUrl = `${environment.apiBaseUrl}Staff`;

  public getStaffBySearchTerms(staffSearchTerms: StaffModel.StaffSearchTerms): Observable<StaffModel.StaffMember[]> {
    const httpParams = HttpParamsUtilities.toHttpParams(staffSearchTerms);
    return this.http.get<StaffModel.StaffMember[]>(`${this.staffUrl}/StaffMembers`, {  params: httpParams });
  }

  public getStaffMember(staffMemberId: string): Observable<StaffModel.StaffMember> {
    return this.http.get<StaffModel.StaffMember>(`${this.staffUrl}/${staffMemberId}`);
  }
}
