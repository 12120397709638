<div class="container header-title">
    <div class="container">
        <h1>Student details</h1>
    </div>
</div>
<div class="container" *ngIf="viewData$ | async as viewData">
    <div class="container">
        <div class="header">
            <div>
                <a  (click)="onBackToStaffClick()"><mat-icon>arrow_back</mat-icon> Back to Staff Members</a>
                <h3>Student Profile</h3>
            </div>
            <div><app-additional-info (fieldSelected)="onFieldSelected($event)" /></div>
        </div>
        <div mat-accordion class="mat-accordion">
            <mat-expansion-panel *ngIf="viewData.staffMember as staffMember" expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Student Personal Info
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="field name-field">
                    <h3 class="value">{{staffMember.name}}</h3>
                    <span class="name">Last, First, Middle Name</span>
                </div>
                <div class="field">
                    <span class="name">SSID</span>
                    <span class="value">{{staffMember.name}}</span>
                </div>
                <div class="field">
                    <span class="name">License Number</span>
                    <span class="value">{{staffMember.teacherLicenseNumber}}</span>
                </div>
                <div class="field">
                    <span class="name">Licensure Check</span>
                    <span class="value">{{staffMember.licensureCheck}}</span>
                </div>
                <div class="field">
                    <span class="name">District</span>
                    <span class="value">{{staffMember.district}}</span>
                </div>
                <div class="field">
                    <span class="name">School</span>
                    <span class="value">{{staffMember.school}}</span>
                </div>
                <div class="field">
                    <span class="name">Date of Birth</span>
                    <span class="value">{{staffMember.dateOfBirth}}</span>
                </div>
            </mat-expansion-panel>
            <ng-container *ngFor="let category of viewData.staffCategories">
                <ng-template [ngTemplateOutlet]="categoryPanel" [ngTemplateOutletContext]="{category: category}"></ng-template>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #categoryPanel let-category="category">
    <mat-expansion-panel [(expanded)]="category.expanded" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{category.name}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let field of category.fields">
            <div class="field">
                <span class="name">{{field.name}}</span>
                <span class="value">{{field.value }}</span>
            </div>
        </ng-container>
    </mat-expansion-panel>
</ng-template>