import { Component } from '@angular/core';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorCategoryTabsComponent } from './shared/error-category-tabs/error-category-tabs.component';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { SchoolService } from 'app/services/school/school.service';
import { DistrictSelectComponent } from './shared/district-select/district-select.component';
import { District } from 'app/services/school/school.model';
import { PageScope } from 'app/enums/page-scope';
import { ErrorLogModel } from './error-log.model';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-error-log',
  standalone: true,
  imports: [
    BreadcrumbComponent, 
    ErrorCategoryTabsComponent,
    RouterOutlet, 
    CommonModule, 
    DistrictSelectComponent
  ],
  templateUrl: './error-log.component.html',
  styleUrl: './error-log.component.scss'
})
export class ErrorLogComponent {

  public viewData$: Observable<ErrorLogModel.ViewModel> = this._userService.getUserProfile().pipe(
    filter(() => this._userService.isUserAuthenticated()),
    mergeMap(userProfile => {
      switch (userProfile!.pageScope) {
        case PageScope.State:
          return this.handleStateScope(userProfile!.scopeId);
        case PageScope.District:
          return this.handleDistrictScope(userProfile!.scopeId);
        case PageScope.School:
          return this.handleSchoolScope(userProfile!.scopeId);
        default:
          throw new Error('User has no page scope.');
      }
    })
  );

  protected PageScope = PageScope;

  public constructor(
    private _userService: UserService,
    private _router: Router,
    private _schoolService: SchoolService) {
    //
  }

  protected onErrorCategoryTabSelected(tabName: string): void { 
    this._router.navigate([`/error-log/${tabName}`]);
  }

  protected onSearchClicked(district: District): void {
    console.log(district);
    //this._sessionStateService.setScopeId(district.id);
  }

  private handleStateScope(scopeId?: number): Observable<ErrorLogModel.ViewModel> {
    return this._schoolService.getDistricts().pipe(
        map(districts => ({
          pageScope: PageScope.State,
          districts: districts,
          scopeId: scopeId
        }))
      );
  }
  
  private handleDistrictScope(scopeId?: number): Observable<ErrorLogModel.ViewModel> {
    if (scopeId) {
      return this._schoolService.getDistrict(scopeId).pipe(
        map(district => ({
          pageScope: PageScope.District,
          district: district,
          scopeId: scopeId
        }))
      );
    }
    throw new Error('The current user does not have a district assigned in profile.');
  }
  
  private handleSchoolScope(scopeId?: number): Observable<ErrorLogModel.ViewModel> {
    if (scopeId) {
      return this._schoolService.getSchool(scopeId).pipe(
        map(school => ({
          pageScope: PageScope.School,
          school: school,
          scopeId: scopeId
        }))
      );
    }
    throw new Error('The current user does not have a school assigned in profile.');
  }
}
