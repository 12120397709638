import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { StudentModel } from '../../../../services/student/student.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SsidFormatPipe } from 'app/pipes/ssid-format/ssid-format.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { TdoeDsModule } from '@tdoe/design-system';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { Router } from '@angular/router';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.model';

@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrl: './student-table.component.scss',
  standalone: true,
  imports: [
    AdditionalInfoComponent, 
    CommonModule, 
    DatePipe, 
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule, 
    SsidFormatPipe,
    TdoeDsModule,
    MatSortModule
  ]
})
export class StudentTableComponent implements  AfterViewInit, OnChanges  {

  @Input({required: true})
  public dataSource!: MatTableDataSource<StudentModel.Student>;

  @Input()
  public selectedDistrictId?: number;

  @Input()
  public selectedSchoolId?: number;
  
  @ViewChild(MatPaginator) 
  public paginator!: MatPaginator;

  @ViewChild(MatSort)
  public sort!: MatSort;

  @ViewChild(AdditionalInfoComponent)
  protected additionalInfoComponent!: AdditionalInfoComponent;

  private _columns = [ 'name', 'ssid', 'dateOfBirth', 'grade', 'enrollmentStartDate', 'enrollmentEndDate', 'code'];
  protected contextColumns: string[] = [];
  protected dynamicColumns: AdditionalInfoModel.Field[] = [];

  protected viewModel = {
      dataSource: this.dataSource,
      displayedColumns: this._columns
    };

  public constructor(
    private _router: Router,
    private _changeDetector: ChangeDetectorRef
  ) {}

  private adjustContextColumns(): void {
    this.contextColumns = [];

    if (!this.selectedDistrictId && !this.selectedSchoolId) {
      this.contextColumns.push('district');
    }

    if (!this.selectedSchoolId) {
      this.contextColumns.push('school');
    }

    this.updateDisplayColumns();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDistrictId'].currentValue || changes['selectedSchoolId'].currentValue) {
      this.adjustContextColumns();
    }
  }

  public ngOnInit(): void {
    this.adjustContextColumns();
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this._changeDetector.detectChanges();
  }

  protected onAdditionalInfoFieldSelected(e: AdditionalInfoModel.Category[]): void {
    this.dynamicColumns = [];
    const fields = e.flatMap(_ => _.fields);

    // Add newly selected fields
    fields.forEach(fld => {
      if (fld.selected && !this.dynamicColumns.find(_ => _.key === fld.key)) {
        this.dynamicColumns.push(fld);
      }
    });

    // Remove newly deselected fields
    this.dynamicColumns.forEach(dcol => {
      const fld = fields.find(_ => _.key === dcol.key);
      if (!fld || !fld.selected) {
        this.dynamicColumns = this.dynamicColumns.filter(_ => _.key !== dcol.key);
      }
    });

    this.updateDisplayColumns();
  }

  public onPageSizeChange($event: Event) : void {
    const pageSizeSelect = $event.target as HTMLSelectElement;
    this.paginator._changePageSize(Number.parseInt(pageSizeSelect.value));
  }

  protected onRowClicked(student: StudentModel.Student): void {
    this._router.navigate(['data-lookup','student-view', student.id, StringUtilities.FormatStringForUrl(student.name)]);
  }

  private updateDisplayColumns(): void {
    this.viewModel.displayedColumns = [
      ...this._columns,
      ...this.contextColumns,
      ...this.dynamicColumns.map(_ => _.key)
    ];
    console.log(this);
  }
}