import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StudentEnrollmentClubMembership } from 'app/models';

@Component({
  selector: 'app-enrollment-membership',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule
  ],
  templateUrl: './enrollment-membership.component.html',
  styleUrl: './enrollment-membership.component.scss'
})
export class EnrollmentMembershipComponent implements OnChanges {

  @Input({ required: true })
  public memberships?: StudentEnrollmentClubMembership[];

  protected datasource!: MatTableDataSource<StudentEnrollmentClubMembership>;
  protected cols = ['clubID', 'ctsoChapterID', 'ctsoMembershipID', 'beginDate', 'endDate'];

  public ngOnChanges(changes: SimpleChanges): void {
      if (changes['memberships'].currentValue) {
        this.loadTableData();
      }
  }

  private loadTableData(): void {
    this.datasource = new MatTableDataSource<StudentEnrollmentClubMembership>(this.memberships);
  }

}
