import { Injectable } from '@angular/core';
import { NavItem } from '@tdoe/design-system';
import { UserRights } from 'app/constants/user-rights';
import { Observable, of, map, mergeMap, iif } from 'rxjs';
import { ErrorLogModel, ErrorLogService } from '../error-log/error-log.service';
import { TypeGuards } from 'app/type-guards';
import { PageScope } from 'app/enums/page-scope';
import { UserService } from '../user/user.service';

interface NavItemFilterable extends NavItem {
  right?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderNavItemsService {

  private _navItemsFilterable$: Observable<NavItemFilterable[]> = of([
    {
      id: 'dashboard',
      text: 'Dashboard',
    },
    {
      id: 'data-lookup',
      text: 'Data Lookup',
      children: [
        { id: 'data-lookup/school', text: 'School' },
        { id: 'data-lookup/class', text: 'Class' },
        { id: 'data-lookup/staff', text: 'Staff' },
        { id: 'data-lookup/student', text: 'Student' },
        { id: 'data-lookup/statewide', text: 'Statewide' }
      ]
    },
    {
      id: 'error-log',
      text: 'Error Log',
      children: [
        { id: 'error-log/school', text: 'School' },
        { id: 'error-log/class', text: 'Class' },
        { id: 'error-log/staff', text: 'Staff' },
        { id: 'error-log/student', text: 'Student' }
      ]
    },
    {
      id: 'reports',
      text: 'Reports'
    },
  ] as NavItemFilterable[]);

  private _navItems$: Observable<NavItem[]> = this._navItemsFilterable$.pipe(
    mergeMap(navItems => 
     iif(() => this._userService.isUserAuthenticated(),
      // user is authenticated
      this._userService.getUserProfile().pipe(
        mergeMap(userProfile => this.getErrorCounts(userProfile!.pageScope, userProfile!.scopeId).pipe(
          map(errorCounts => ({
            navItems,
            userRights: userProfile!.rights,
            errorCounts: ErrorLogService.convertToErrorCounts(errorCounts)
          })),
          map(data => {
            const navItems = this.setAlertBadge(data.navItems, data.errorCounts.total);
            return this.filterNavItems(navItems, data.userRights);
          })
        ))
      ),
      // user is not authenticated
      of(this.filterNavItems(navItems, []))
     )
    )
  );

  public constructor(
    private _userService: UserService,
    private _errorLogService: ErrorLogService) {
    //
  }

  private getErrorCounts(pageScope: PageScope, scopeId?: number) : Observable<ErrorLogModel.CategoryErrorCount[]>{
    switch (pageScope) {
      case PageScope.State:{
        return this._errorLogService.getStateCounts();
      }
      case PageScope.District: {
        return this._errorLogService.getDistrictCounts(scopeId!);
      }
      case PageScope.School: {
        return this._errorLogService.getSchoolCounts(scopeId!);
      }
      default: {
        throw Error('Unknown page scope.');
      }
    }
  }

  private setAlertBadge(navItems: NavItemFilterable[], totalErrors: number): NavItemFilterable[] {
    const errorLogNavItem = navItems.find(item => item.id === 'error-log');
    if (TypeGuards.isNavItem(errorLogNavItem)) {
      errorLogNavItem.alert = (totalErrors > 0);
    }
    return navItems;
  }

  public getNavItems(): Observable<NavItem[]> {
    return this._navItems$;
  }

  private filterNavItems(navItems: NavItemFilterable[], userRights: string[]): NavItem[] {
    return navItems.filter(navItem => {
      if (navItem.right && !userRights.includes(navItem.right)) {
        return false;
      }
      if (navItem.children) {
        navItem.children = this.filterNavItems(navItem.children, userRights);
        return navItem.children.length > 0;
      }
      return true;
    });
  }
}