@if (datasource.data) {
    <mat-table [dataSource]="datasource" matSort>

        <ng-container matColumnDef="clubID">
            <mat-header-cell *matHeaderCellDef> Club ID </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.clubID}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="ctsoChapterID">
            <mat-header-cell *matHeaderCellDef> CTSO Chapter ID </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.ctsoChapterID}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="ctsoMembershipID">
            <mat-header-cell *matHeaderCellDef> CTSO Membership ID </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.ctsoMembershipID}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="beginDate">
            <mat-header-cell *matHeaderCellDef> Start date </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.beginDate | date}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef> End date </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.endDate | date}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="cols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: cols"></mat-row>
    
    </mat-table>
}