import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface BreadcrumbItem {
  label: string;
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService  {
  private _breadcrumbSubject = new BehaviorSubject<BreadcrumbItem[]>([]);
  private _breadcrumbs$: Observable<BreadcrumbItem[]> = this._breadcrumbSubject.asObservable();

  public constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private destroyReference: DestroyRef
  ) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.buildBreadcrumbs(this.activatedRoute.root)),
        takeUntilDestroyed(this.destroyReference)
      ).subscribe(breadcrumbs => this.setBreadcrumbs(breadcrumbs));
  }

  public getBreadcrumbs(): Observable<BreadcrumbItem[]> {
    return this._breadcrumbs$;
  }

  private buildBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return this.filterDuplicateLabels(breadcrumbs);
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET || !child.snapshot.title) {
        continue;
      }

      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      url += `/${routeURL}`;

      const breadcrumb: BreadcrumbItem = {
        label: child.snapshot.title,
        url
      };
      breadcrumbs.push(breadcrumb);

      return this.filterDuplicateLabels(this.buildBreadcrumbs(child, url, breadcrumbs));
    }

    return this.filterDuplicateLabels(breadcrumbs);
  }

  private filterDuplicateLabels(breadcrumbs: BreadcrumbItem[]): BreadcrumbItem[] {
    const filteredBreadcrumbs: BreadcrumbItem[] = [];
  
    for (let i = 0; i < breadcrumbs.length; i++) {
      // Check if the current item is the last one or if its label is different from the next item's label
      if (i === breadcrumbs.length - 1 || breadcrumbs[i].label !== breadcrumbs[i + 1].label) {
        filteredBreadcrumbs.push(breadcrumbs[i]);
      }
    }
  
    return filteredBreadcrumbs;
  }

  private setBreadcrumbs(breadcrumbs: BreadcrumbItem[]): void {
    this._breadcrumbSubject.next(breadcrumbs);
  }
}
