import { Component } from '@angular/core';

@Component({
  selector: 'app-data-lookup-staff',
  templateUrl: './data-lookup-staff.component.html',
  styleUrl: './data-lookup-staff.component.scss'
})
export class DataLookupStaffComponent {

}
