<div class="container">
    <div class="container">
        <app-breadcrumb/>
        <h1>Dashboard</h1>
    </div>
</div>
<ng-container *ngIf="viewModel$ | async as viewModel">
   <app-scope-context-filter 
        [userPageScope]="viewModel.userPageScope" 
        [userScopeId]="viewModel.userScopeId"
        [selectedYear]="viewModel.selectedYear" 
        [selectedSchoolId]="viewModel.selectedSchoolId" 
        [selectedDistrictId]="viewModel.selectedDistrictId" 
        (searchClick)="onSearchClicked($event)" />
    <app-error-category-cards 
        *ngIf="viewModel.isSelectedDataValid" 
        [pageScope]="viewModel.selectedPageScope!" 
        [scopeId]="viewModel.selectedSchoolId ?? viewModel.selectedDistrictId"
        [year]="viewModel.selectedYear!" />
    <app-average-daily-membership 
        *ngIf="viewModel.isSelectedDataValid" 
        [pageScope]="viewModel.selectedPageScope!" 
        [scopeId]="viewModel.selectedSchoolId ?? viewModel.selectedDistrictId"
        [year]="viewModel.selectedYear!" />
    <app-demographics 
        *ngIf="viewModel.isSelectedDataValid" 
        [pageScope]="viewModel.selectedPageScope!" 
        [scopeId]="viewModel.selectedSchoolId ?? viewModel.selectedDistrictId"
        [year]="viewModel.selectedYear!" />
</ng-container>