<div class="container">
    <div class="container">
        <div class="filter">
            <label for="yearSelect">Select Year</label>
            <select id="yearSelect" [(ngModel)]="year">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
            </select>
        </div>
        <div class="filter">
            <label for="schoolSelect">Select School</label>
            <select id="schoolSelect" [(ngModel)]="schoolId">
                <option *ngFor="let school of schools" [value]="school.id">{{ school.name }}</option>
            </select>
        </div>
        <div class="buttons">
            <button tdoe-button (click)="onApplyClick()">Apply</button>
            <button tdoe-button (click)="onResetClick()">Reset</button>
        </div>
    </div>
</div>
  
