import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {

  @Output() public buttonClick = new EventEmitter<void>();
  
  public onButtonClick(): void {
    this.buttonClick.emit();
  }

  @Input()
  public imageURL!: string;
  
  @Input()
  public imageAltText!: string;
  
  @Input()
  public title!: string;
  
  @Input()
  public content!: string;
  
  @Input()
  public buttonText!: string;
}
