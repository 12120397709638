import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, tap } from 'rxjs';
import { SchoolModel } from './school.model';
export { SchoolModel } from './school.model';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  public constructor(private http: HttpClient) { }

  public static SchoolUrl = environment.apiBaseUrl + 'School/School';
  public static SchoolsUrl = environment.apiBaseUrl + 'School/Schools';
  public static DistrictUrl = environment.apiBaseUrl + 'School/District';
  public static DistrictsUrl = environment.apiBaseUrl + 'School/Districts';

  public getSchools(districtId: number): Observable<SchoolModel.School[]> {
    if(!districtId) throw new Error('districtId is required.');
    const params = new HttpParams().set('districtId', districtId.toString());
    const storageKey = `${SchoolService.SchoolUrl}?${params}`;
    const storedSchools = sessionStorage.getItem(storageKey);

    if(storedSchools){
      return of(JSON.parse(storedSchools) as SchoolModel.School[]);
    }
    
    return this.http.get<SchoolModel.School[]>(SchoolService.SchoolsUrl, { params }).pipe(
      tap(schools => sessionStorage.setItem(storageKey, JSON.stringify(schools)))
    );
  }

  public getDistricts(): Observable<SchoolModel.District[]> {
    const storageKey = SchoolService.DistrictsUrl;
    const storedSchools = sessionStorage.getItem(storageKey);

    if(storedSchools){
      return of(JSON.parse(storedSchools) as SchoolModel.District[]);
    }
    return this.http.get<SchoolModel.District[]>(SchoolService.DistrictsUrl).pipe(
      tap(districts => sessionStorage.setItem(storageKey , JSON.stringify(districts)))
    );
  }

  public getSchool(schoolId: number): Observable<SchoolModel.School>{
    return this.http.get<SchoolModel.School>(SchoolService.SchoolUrl, { params: { schoolId } });
  }

  public getDistrict(districtId: number): Observable<SchoolModel.District>{
    return this.http.get<SchoolModel.School>(SchoolService.DistrictUrl, { params: { districtId } });
  }
}
