<div class="container header-title">
    <div class="container">
        <h1>Statewide Search</h1>
    </div>
</div>
<div class="container">
    <div class="container">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
            <mat-tab label="Student">
                <app-student-filter (searchClicked)="onSearchClickedStudent($event)"/>
                <ng-template *ngIf="!showStudentTable" [ngTemplateOutlet]="selectOptionsAboveImage"></ng-template>
                <app-student-table *ngIf="showStudentTable" [dataSource]="studentDataSource"/>
            </mat-tab>
            <mat-tab label="Staff">
                <app-staff-filter (searchClicked)="onSearchClickedStaff($event)"/>
                <ng-template *ngIf="!showStaffTable" [ngTemplateOutlet]="selectOptionsAboveImage"></ng-template>
                <app-staff-table *ngIf="showStaffTable" [dataSource]="staffDataSource"/>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<ng-template #selectOptionsAboveImage>
    <div class="image-section">
        <img Src="assets/images/select-options-above.png"/>
    </div>
</ng-template>