import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { StudentFilterComponent, StudentFilterModel } from '../shared/student-filter/student-filter.component';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { StudentService, StudentModel } from 'app/services/student/student.service';
import { StudentTableComponent } from '../shared/student-table/student-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { StaffFilterComponent, StaffFilterModel } from '../shared/staff-filter/staff-filter.component';
import { StaffModel, StaffService } from 'app/services/staff/staff.service';
import { StaffTableComponent } from '../shared/staff-table/staff-table.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-data-lookup-statewide',
  templateUrl: './data-lookup-statewide.component.html',
  styleUrl: './data-lookup-statewide.component.scss',
  standalone: true,
  imports: [
    MatTabsModule, 
    NgTemplateOutlet, 
    CommonModule, 
    StudentFilterComponent, 
    StaffFilterComponent,  
    StaffTableComponent, 
    StudentTableComponent,
    RouterOutlet
  ]
})
export class DataLookupStatewideComponent {

  protected showStudentTable: boolean;
  protected showStaffTable: boolean;
  protected studentDataSource = new MatTableDataSource<StudentModel.Student>();
  protected staffDataSource = new MatTableDataSource<StaffModel.StaffMember>();

  public constructor(private studentService: StudentService, private staffService: StaffService) {
    this.showStudentTable = false;
    this.showStaffTable = false;
  }

  protected onSearchClickedStudent(studentSearchTerms: StudentFilterModel.StudentSearchTerms): void {
    this.fetchStudents(studentSearchTerms);
  }

  protected onSearchClickedStaff(personSearchTerms: StaffFilterModel.StaffSearchTerms): void {
    this.fetchStaff(personSearchTerms);
  }

  private fetchStudents(studentSearchTerms: StudentFilterModel.StudentSearchTerms): void {
    if ( !this.hasSearchTerms(studentSearchTerms)) {
      this.studentDataSource.data = [];
      this.showStudentTable = false;
      return;
    }

    this.studentService.getStudentsBySearchTerms(studentSearchTerms).subscribe(results => {
      this.studentDataSource.data = results;
      this.showStudentTable = true;
    });
  }

  private fetchStaff(staffSearchTerms: StaffFilterModel.StaffSearchTerms): void {
    if ( !this.hasSearchTerms(staffSearchTerms)) {
      this.staffDataSource.data = [];
      this.showStaffTable = false;
      return;
    }

    this.staffService.getStaffBySearchTerms(staffSearchTerms).subscribe(results => {
      this.staffDataSource.data = results;
      this.showStaffTable = true;
    });
  }

  private hasSearchTerms(searchTerms: object): boolean  {
    return Object.keys(searchTerms).length > 0;
  }
}
