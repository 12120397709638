import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PageScope } from 'app/enums/page-scope';
import { MembershipModel } from 'app/services/membership/membership.model';
import { MembershipService } from 'app/services/membership/membership.service';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { BehaviorSubject, Observable, combineLatest, filter, debounceTime, map, tap, mergeMap } from 'rxjs';

interface ViewModel {
  chartOptions: EChartsOption
}

@Component({
  selector: 'app-adm-by-grade',
  standalone: true,
  imports: [CommonModule, NgxEchartsModule],
  templateUrl: './adm-by-grade.component.html',
  styleUrl: './adm-by-grade.component.scss'
})
export class AdmByGradeComponent {

  @Input({required: true})
  public set pageScope(value: PageScope) {
    this._pageScopeSubject$.next(value);
  }

  @Input()
  public set scopeId(value: number | undefined) {
    this._scopeIdSubject$.next(value);
  }

  @Input({required: true})
  public set year(value: number) {
    this._yearSubject$.next(value);
  }

  private _scopeIdSubject$ = new BehaviorSubject<number | undefined>(undefined);

  private _pageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);
  
  private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);
  
  private _colorPalette = ['#FF0000','#002d72','#cecf25','#4594ce','#00FFFF','#ff8b0f']; 

  private _chartOptions: EChartsOption =  {
    title: {
      text: 'ADM by Grade',
      left: 'left',
      top: 5,
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: 10
    },
    xAxis: {
      type: 'category',
      data: ['Grade']
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value: number): string {
          if (value >= 1000) {
            return (value / 1000) + 'k';
          }
          return value.toString();
        }
      }
    },
    grid: {
      left: '15%'
    }
  };

  protected viewModel$: Observable<ViewModel> =
    combineLatest([
      this._scopeIdSubject$,
      this._pageScopeSubject$.pipe(filter(pageScope => pageScope !== undefined)),
      this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([scopeId, pageScope, year]) => ({scopeId, pageScope, year})),
      mergeMap(data => this.getMembershipByGradeSummaries(data.year, data.pageScope, data.scopeId).pipe(
        map(membershipByGradeSummaries => ({
          chartOptions: {
            ...this._chartOptions,
            legend: {
              ...this._chartOptions.legend,
              data: membershipByGradeSummaries.map(membershipByGradeSummary => membershipByGradeSummary.grade)
            },
            series: this.getBarSeries(membershipByGradeSummaries)
          }
        }))
      ))
    );

    public constructor(private _membershipService: MembershipService) {
      //
    }

    private getMembershipByGradeSummaries(year_: number, pageScope_: PageScope, scopeId_?: number) : Observable<MembershipModel.MembershipByGradeSummary[]> {
      switch(pageScope_){
        case PageScope.School: {
          if (scopeId_) {
            return this._membershipService.getSchoolMembershipByGradeSummary(year_, scopeId_);
          }
          throw new Error('scopeId does not have a value when pageScope_ is school-scope.');
        }
        case PageScope.District: {
          if (scopeId_) {
            return this._membershipService.getDistrictMembershipByGradeSummary(year_, scopeId_);
          }
          throw new Error('scopeId does not have a value when pageScope_ is district-scope.');
        }
        case PageScope.State: {
          return this._membershipService.getStateMembershipByGradeSummary(year_);
        }
      }
    }

    private getBarSeries(membershipByGradeSummaries: MembershipModel.MembershipByGradeSummary[]): BarSeriesOption[] {
      return membershipByGradeSummaries
        .map((membershipByGradeSummary, index) => ({
          name: membershipByGradeSummary.grade,
          data: [membershipByGradeSummary.baseADM],
          type: 'bar',
          itemStyle:  (index < this._colorPalette.length) 
            ? {
              color: this._colorPalette[index]
            } 
            : undefined
        }));
    }
}
