import { Component, Input } from '@angular/core';
import { AdmByGradeComponent } from './adm-by-grade/adm-by-grade.component';
import { GenderComponent } from './gender/gender.component';
import { RaceComponent } from './race/race.component';
import { PageScope } from 'app/enums/page-scope';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';

interface ViewModel {
  pageScope: PageScope,
  scopeId?: number,
  year: number
}

@Component({
  selector: 'app-demographics',
  standalone: true,
  imports: [CommonModule, AdmByGradeComponent, GenderComponent, RaceComponent],
  templateUrl: './demographics.component.html',
  styleUrl: './demographics.component.scss'
})
export class DemographicsComponent {
    @Input({required: true})
    public set pageScope(value: PageScope) {
      this._pageScopeSubject$.next(value);
    }

    @Input({required: true})
    public set scopeId(value: number | undefined) {
      this._scopeIdSubject$.next(value);
    }

    @Input({required: true})
    public set year(value: number) {
      this._yearSubject$.next(value);
    }

    private _scopeIdSubject$ = new BehaviorSubject<number | undefined>(undefined);
  
    private _pageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);
    
    private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);

    protected viewModel$: Observable<ViewModel>  =
      combineLatest([
        this._scopeIdSubject$,
        this._pageScopeSubject$.pipe(filter(pageScope => pageScope !== undefined)),
        this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
      ]).pipe(
        debounceTime(300),
        map(([scopeId, pageScope, year]) => ({scopeId, pageScope, year})),
        map(data=> ({
          pageScope: data.pageScope!,
          scopeId: data.scopeId,
          year: data.year!
        }))
      );
}
