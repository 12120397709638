import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StudentEnrollmentHistory } from 'app/models';

@Component({
  selector: 'app-enrollment-history',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule
  ],
  templateUrl: './enrollment-history.component.html',
  styleUrl: './enrollment-history.component.scss'
})
export class EnrollmentHistoryComponent implements OnChanges {

  @Input({ required: true })
  public history?: StudentEnrollmentHistory[];

  protected datasource!: MatTableDataSource<StudentEnrollmentHistory>;
  protected cols = ['grade', 'year', 'district', 'school', 'enrollment', 'withdrawal', 'tos', 'primaryDistrict', 'primarySchool'];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['history'].currentValue) {
      this.loadTableData();
    }
  }

  private loadTableData(): void {
    this.datasource = new MatTableDataSource<StudentEnrollmentHistory>(this.history);
  }

}
