<div class="container">
    <div class="container">
        <div class="image-section">
            <img [src]="imageURL" [alt]="imageAltText">
        </div>
        <div class="text-section">
            <h1 [innerHTML]="title"></h1>
            <p [innerText]="content"></p>
            <button tdoe-button [innerText]="buttonText" (click)="onButtonClick()"></button>
        </div>
    </div>
</div>
