import { PageScope } from 'app/enums/page-scope';

export * as UserModel from './user.model';

export interface UserProfile {

    pageScope: PageScope;

    /**
     * @description
     * School Id if page scope is school.
     * District Id if page scope is district. 
     */
    scopeId?: number;

    rights: string[];
}