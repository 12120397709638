import { Component, OnInit } from '@angular/core';
import { InactivityService } from '@tdoe/design-system';

@Component({
  selector: 'app-inactivity',
  standalone: true,
  imports: [],
  templateUrl: './inactivity.component.html',
  styleUrl: './inactivity.component.scss'
})
export class InactivityComponent implements OnInit {

  constructor(private inactivityService: InactivityService) { }

  ngOnInit(): void {
    this.inactivityService.startMonitoring();
  }

  resetTimer(): void {
    this.inactivityService.resetInactivityTimer();
  }

  stopMonitoring(): void {
    this.inactivityService.stopMonitoring();
  }
}
