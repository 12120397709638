export * as StudentModel from './student.model';

import { StudentDemographics, StudentEnrollment } from '../../models';

export interface Student {
  id: number;
  name: string;
  ssid: string;
  serviceType: string;
  grade: number;
  enrollmentStartDate: Date;
  enrollmentEndDate: Date;
  /**probably enrollmentCode */
  code: string;
  withdrawalEndDate: Date;
  withdrawalCode: string;
  language: string;
  ethnicity: string;
  gender: string;
  district: string;
  serviceDistrict: string;
  school: string;
  serviceSchool: string;
  //#region Additional Data
  attendanceDate: Date;
  attendanceType: string;
  standardDayMinutes: number;
  label: string;
  localClassNumber: string;
  courseCode: string;
  classType: string;
  vocOutsideIP: string;
  enrolmentPeriodStartDate: Date;
  enrolmentPeriodEndDate: Date;
  assignmentStartDates: Date;
  assignmentEndDates: Date;
  classStartDates: Date;
  classification: string;
  beginDate: Date;
  endDate: Date;
  clubID: string;
  enrollmentPeriodStart: Date;
  enrollmentPeriodEnd: Date;
  disciplinaryPeriodBegin: Date;
  disciplinaryPeriodEnd: Date;
  disciplinaryType: string;
  disciplinaryReason: string;
  sped: string;
  zeroTolerance: string;
  //#endregion
  demographics: StudentDemographics;
  enrollment: StudentEnrollment;
}

export interface StudentSearchTerms {
  year?: number,
  schoolId?: number,
  districtId?: number,
  lastName?: string,
  firstName?: string,
  middleName?: string,
  ssid?: string,
  dateOfBirth?: Date,
  grade?: string,
  serviceType?: string
}

export interface GenderSummary {
  gender: string;
  count: number;
}

export interface EthnicitySummary {
  ethnicity: string;
  count: number;
}