<div class="container">
  <div class="container">
    <div class="image-section">
      <img src="/assets/images/man-working.png" alt="Man working">
    </div>
    <div class="text-section">
      <h1>About SWORD<span class="bold-red">.</span></h1>
      <p>Statewide Operations Reporting Dashboard (SWORD) collects, maintains, and organizes education data for public
        school
        districts in Tennessee. District data, collected from Student Information Systems (SIS) Vendors, includes
        information
        on students from PreK to 12th, as well as teacher information. The goal of SWORD is to ensure that all students
        in
        Tennessee are on track to succeed by meeting the requirements set by both the State and federal government.
        Through this
        collection and maintenance of educational records, we are able to promote student achievement, assist with
        public school
        funding along with providing a comprehensive view of the education system in Tennessee.</p>
      <button tdoe-button (click)="onMoreAboutSwordClick()">More About SWORD</button>
    </div>
  </div>
</div>