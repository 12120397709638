<div class="container">
    <div class="container">
        <div class="header">
            <h3>Staff Search</h3>
            <div class="controls">
                <div class="page-size">
                    <span>Results Per Page</span>
                    <select name="pageSize" [(ngModel)]="paginator.pageSize" (change)="onPageSizeChange($event)"> 
                        <option *ngFor="let pageSize of paginator.pageSizeOptions" [value]="pageSize">{{pageSize}}</option>
                    </select>
                </div>
                <div class="buttons">
                    <button tdoe-button>Filter Data</button>
                    <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item>CSV</button>
                        <button mat-menu-item>PDF</button>
                        <button mat-menu-item>Excel</button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="table-title">
            <span>Staff Data</span>
        </div>
        <table mat-table [dataSource]="dataSource" >
            
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name Last, First, Middle</th>
                <td mat-cell *matCellDef="let staff"> {{staff.name}} </td>
            </ng-container>
        
            <ng-container matColumnDef="teacherLicenseNumber">
                <th mat-header-cell *matHeaderCellDef> Teacher License No </th>
                <td mat-cell *matCellDef="let staff" > {{staff.teacherLicenseNumber}} </td>
            </ng-container>
        
            <ng-container matColumnDef="licensureCheck">
                <th mat-header-cell *matHeaderCellDef> Licensure Check </th>
                <td mat-cell *matCellDef="let staff"> {{staff.licensureCheck}} </td>
            </ng-container>

            <ng-container matColumnDef="district">
                <th mat-header-cell *matHeaderCellDef> District </th>
                <td mat-cell *matCellDef="let staff"> {{staff.district}} </td>
            </ng-container>

            <ng-container matColumnDef="school">
                <th mat-header-cell *matHeaderCellDef> School </th>
                <td mat-cell *matCellDef="let staff"> {{staff.school}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
        </table>
        
        <mat-paginator
            #paginator
            [pageSize]="12"
            [pageSizeOptions]="[6, 12, 24]"
            [showFirstLastButtons]="false"
            [hidePageSize]="true">
        </mat-paginator>
    </div>

</div>