<div class="container">
    <div class="container">
        <ng-container *ngIf="viewModel$ | async as viewModel">
            <div class="flex-filter">
                <mat-form-field appearance="outline" style="width:120px;">
                    <mat-label>Select year</mat-label>
                    <mat-select [(value)]="viewModel.selectedYear" (selectionChange)="onYearChange($event.value)">
                        @for (year of viewModel.years;track year) {
                            <mat-option [value]="+year">{{year}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" style="flex-grow:1">
                    <mat-label>Select district</mat-label>
                    <mat-select [(value)]="viewModel.selectedDistrictId" (selectionChange)="onDistrictIdChange($event.value)">
                        <mat-option [value]="undefined"></mat-option>
                        @for (district of viewModel.districts;track district) {
                            <mat-option [value]="district.id">{{district.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" style="flex-grow:1">
                    <mat-label>Select school</mat-label>
                    <mat-select [(value)]="viewModel.selectedSchoolId" (selectionChange)="onSchoolChange($event.value)" [disabled]="!!!viewModel.selectedDistrictId">
                        <mat-option [value]="undefined"></mat-option>
                        @for(school of viewModel.schools;track school) {
                            <mat-option [value]="school.id">{{school.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <div class="buttons">
                    <button tdoe-button [disabled]="!viewModel.isValid" (click)="onSearchClick(viewModel)">Apply</button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
  
