@if (datasource.data) {
    <mat-table [dataSource]="datasource" matSort>

        <ng-container matColumnDef="classificationCode">
            <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.classificationCode}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="classificationDateStart">
            <mat-header-cell *matHeaderCellDef> Start date </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.classificationDateStart | date}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="classificationDateEnd">
            <mat-header-cell *matHeaderCellDef> End date </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.classificationDateEnd | date}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="homelessResidence">
            <mat-header-cell *matHeaderCellDef> Homeless </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.homelessInfo.homelessResidence}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="homelessMckinnyVento">
            <mat-header-cell *matHeaderCellDef> McKinny-Vento </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.homelessInfo.homelessMckinnyVento}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="homelessUnaccompaniedYouth">
            <mat-header-cell *matHeaderCellDef> Unaccompanied </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.homelessInfo.homelessUnaccompaniedYouth}}</mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedCols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedCols"></mat-row>
    
    </mat-table>
}