import { Component } from '@angular/core';
import { BreadcrumbItem, BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [RouterModule, CommonModule]
})
export class BreadcrumbComponent {
  public breadcrumbs$: Observable<BreadcrumbItem[]> = this.breadcrumbService.getBreadcrumbs();

  public constructor(private breadcrumbService: BreadcrumbService) { }
}
