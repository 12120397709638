import { Component } from '@angular/core';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-data-lookup',
  standalone: true,
  templateUrl: './data-lookup.component.html',
  styleUrls: ['./data-lookup.component.scss'],
  imports: [BreadcrumbComponent, RouterOutlet]
})
export class DataLookupComponent {

}
