import { HttpParams } from '@angular/common/http';
import dayjs from 'dayjs';

export * as HttpParamsUtilities from '../../utilities/http-params-utilities/http-params-utilities';

export function toHttpParams<T extends object>(paramsObject: T): HttpParams {
  let params = new HttpParams();

  Object.keys(paramsObject).forEach(key => {
    const value = paramsObject[key as keyof T];
    if (value !== undefined && value !== null) {
      if (value instanceof Date) {
        params = params.set(key, dayjs(value).format('MM/DD/YYYY'));
      } else {
        params = params.set(key, value.toString());
      }
    }
  });

  return params;
}
