import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core'
import { PageScope } from 'app/enums/page-scope';
import { StudentModel } from 'app/services/student/student.model';
import { StudentService } from 'app/services/student/student.service';
import { EChartsOption, PieSeriesOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, mergeMap, Observable, tap } from 'rxjs';

interface ViewModel {
  chartOptions: EChartsOption
}

@Component({
  selector: 'app-gender',
  standalone: true,
  imports: [CommonModule, NgxEchartsModule],
  templateUrl: './gender.component.html',
  styleUrl: './gender.component.scss'
})
export class GenderComponent {

  @Input({required: true})
  public set pageScope(value: PageScope) {
    this._pageScopeSubject$.next(value);
  }

  @Input()
  public set scopeId(value: number | undefined) {
    this._scopeIdSubject$.next(value);
  }

  @Input({required: true})
  public set year(value: number) {
    this._yearSubject$.next(value);
  }

  private _scopeIdSubject$ = new BehaviorSubject<number | undefined>(undefined);

  private _pageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);
  
  private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);

  private _colorPalette = ['#FF0000','#002d72','#cecf25','#4594ce','#00FFFF','#ff8b0f']; 

  private _chartOptions: EChartsOption = {
    title: {
      text: 'Gender',
      left: 'left',
      top: 5,
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',

    },
    legend: {
      orient: 'horizontal',
      bottom: 10,
    },
  };

  private _pieSeriesOption: PieSeriesOption = {
    name: 'Gender',
    type: 'pie',
    radius: ['35%', '50%'],
    center: ['50%','40%'],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: 'center'
    },
    labelLine: {
      show: false
    },
  };

  protected viewModel$: Observable<ViewModel>  =
    combineLatest([
      this._scopeIdSubject$,
      this._pageScopeSubject$.pipe(filter(pageScope => pageScope !== undefined)),
      this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([scopeId, pageScope, year]) => ({scopeId, pageScope, year})),
      mergeMap(data => this.getStudentGenderSummary(data.year, data.pageScope, data.scopeId).pipe(
        map(genderSummaries => ({
          chartOptions: {
            ...this._chartOptions,
            legend: {
              ... this._chartOptions.legend,
              formatter: (name: string): string => {
                return this.getLegendLabel(name, genderSummaries);
              },
              data: genderSummaries
                // sorts the legend by count ascending. 
                .sort((a, b) => b.count - a.count )
                .map(genderSummary => genderSummary.gender)
            },
            series: this.getPieSeries(genderSummaries)
          } 
        }))
      ))
    );

    public constructor(private _studentService: StudentService) {
      //
    }

    private getStudentGenderSummary(year_: number, pageScope_: PageScope, scopeId_?: number) : Observable<StudentModel.GenderSummary[]> {
      switch(pageScope_){
        case PageScope.School: {
          if (scopeId_) {
            return this._studentService.getSchoolGenderSummary(year_, scopeId_);
          }
          throw new Error('scopeId does not have a value when pageScope_ is school-scope.');
        }
        case PageScope.District: {
          if (scopeId_) {
            return this._studentService.getDistrictGenderSummary(year_, scopeId_);
          }
          throw new Error('scopeId does not have a value when pageScope_ is district-scope.');
        }
        case PageScope.State: {
          return this._studentService.getStateGenderSummary(year_);
        }
      }
    }

    private getPieSeries(genderSummaries: StudentModel.GenderSummary[]): PieSeriesOption {
      this._pieSeriesOption.data = genderSummaries
        .map((genderSummary, index)=> ({
          value: genderSummary.count,
          name: genderSummary.gender,
          itemStyle: (index < this._colorPalette.length) ? {
            color: this._colorPalette[index]
          } : undefined
        }));

      return this._pieSeriesOption;
    }

    private getLegendLabel(name: string, genderSummaries: StudentModel.GenderSummary[]): string {
      const data = genderSummaries.find((item) => item.gender === name);
      const total = genderSummaries.reduce((acc, item) => acc + item.count, 0);
      const percentage = ((data?.count ?? 0) / total) * 100;
      return `${name}: ${percentage.toFixed(2)}%`;
    }
}


