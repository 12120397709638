import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BreadcrumbComponent } from 'app/components/shared/breadcrumb/breadcrumb.component';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { PageScope } from 'app/enums/page-scope';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, of, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { StudentFilterComponent } from '../shared/student-filter/student-filter.component';
import { StudentTableComponent } from '../shared/student-table/student-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { StudentModel, StudentService } from 'app/services/student/student.service';
import { SchoolService } from 'app/services/school/school.service';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-data-lookup-student',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ScopeContextFilterComponent,
    StudentFilterComponent,
    StudentTableComponent
  ],
  templateUrl: './data-lookup-student.component.html',
  styleUrl: './data-lookup-student.component.scss'
})
export class DataLookupStudentComponent {

  private _studentTableDataSource = new MatTableDataSource<StudentModel.Student>();

  private _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();
  private _scopeContextFilterSelectedData$ = this._userProfileService.getUserProfile().pipe(
    switchMap(userProfile =>  this._scopeContextFilterSelectedDataSubject$.pipe(
      filter(userProfile => !! userProfile),
      startWith({
        pageScope: userProfile!.pageScope,
        year: new Date().getFullYear(),
        schoolId: userProfile!.pageScope === PageScope.School
          ? userProfile!.scopeId!
          : undefined,
        districtId: userProfile!.pageScope === PageScope.District
          ? userProfile!.scopeId!
          : undefined
      }),
      distinctUntilChanged((prev, curr) => this.isCurrentScopeFilterSelectedDataEqualToCurrent(prev, curr)),
      map(searchTerms => ({
        sessionState: userProfile,
        ...searchTerms!
      })),
      shareReplay(1)
    ))
  );

  private _selectedScopeDescriptor$ = this._scopeContextFilterSelectedData$.pipe(
    switchMap(scopeContextFilterData => {
      switch(scopeContextFilterData.pageScope){
        case PageScope.State: {
          return of(`${scopeContextFilterData.year} - Statewide`);
        }
        case PageScope.District: {
          return this._schoolService.getDistrict(scopeContextFilterData.districtId!).pipe(
            map(district => `${scopeContextFilterData.year} - ${district.name}`)
          );
        }
        case PageScope.School: {
          return this._schoolService.getSchool(scopeContextFilterData.schoolId!).pipe(
            map(school => `${scopeContextFilterData.year} - ${school.name}`)
          );
        }
      }
    })
  );

  
  private _studentSearchTermsSubject$ = new BehaviorSubject<StudentModel.StudentSearchTerms>({});
  private _studentTableDataSource$ = combineLatest([
    this._scopeContextFilterSelectedData$, 
    this._studentSearchTermsSubject$
  ]).pipe(
    map(([scopeContextFilterSelectedData, studentSearchTerms]) => ({
      ...studentSearchTerms,
      districtId: scopeContextFilterSelectedData.districtId,
      schoolId: scopeContextFilterSelectedData.schoolId,
      year: scopeContextFilterSelectedData.year
    })),
    distinctUntilChanged<StudentModel.StudentSearchTerms>((prev, curr) => this.isPreviousStudentSearchTermsEqualToCurrent(prev, curr)),
    switchMap(studentSearchTerms => this._studentService.getStudentsBySearchTerms(studentSearchTerms).pipe(
      map((students: StudentModel.Student[]) => {
        this._studentTableDataSource.data = students;
        return this._studentTableDataSource;
      })
    )),
    shareReplay(1)
  );

  protected viewModel$ = combineLatest([
    this._userProfileService.getUserProfile(), 
    this._scopeContextFilterSelectedData$, 
    this._studentTableDataSource$, 
    this._selectedScopeDescriptor$ ]).pipe(
    map(([
      userProfile, 
      scopeContextFilterSelectedData, 
      studentTableDataSource, 
      selectedScopeDescriptor]) => ({ 
        userProfile, 
        scopeContextFilterSelectedData, 
        studentTableDataSource, 
        selectedScopeDescriptor
      })),
    map(data => ({
      userPageScope: data.userProfile!.pageScope,
      userScopeId: data.userProfile!.scopeId,
      selectedPageScope: data.scopeContextFilterSelectedData.pageScope,
      selectedYear: data.scopeContextFilterSelectedData.year,
      selectedSchoolId: data.scopeContextFilterSelectedData.schoolId,
      selectedDistrictId: data.scopeContextFilterSelectedData.districtId,
      studentTableDataSource: data.studentTableDataSource,
      selectedScopeDescriptor: data.selectedScopeDescriptor,
      studentFilterTitle: this.getStudentFilterTitle(data.scopeContextFilterSelectedData.pageScope)
    })),
    shareReplay(1)
  );

  public constructor (
    private _userProfileService: UserService,
    private _studentService: StudentService,
    private _schoolService: SchoolService
  ) {}

  //Clark: move closer to ScopeContextFilterModel.SelectedData so it can be reused
  private isCurrentScopeFilterSelectedDataEqualToCurrent(previous: ScopeContextFilterModel.SelectedData, current: ScopeContextFilterModel.SelectedData): boolean {
    console.debug('onStudentFilterSearchClicked -> isCurrentScopeFilterSelectedDataEqualToCurrent:', previous, current);
    return previous.year === current.year
      && previous.districtId === current.districtId
      && previous.schoolId === current.schoolId;
  }

  //Clark: move closer to StudentModel.StudentSearchTerms so it can be reused
  private isPreviousStudentSearchTermsEqualToCurrent(previous: StudentModel.StudentSearchTerms, current: StudentModel.StudentSearchTerms): boolean {
    console.debug('onStudentFilterSearchClicked -> isPreviousStudentSearchTermsEqualToCurrent:', previous, current);
    return previous?.year === current?.year
      && previous?.districtId === current?.districtId
      && previous?.schoolId === current?.schoolId
      && previous?.ssid === current?.ssid
      && previous?.serviceType == current?.serviceType
      && previous?.grade == current?.grade
      && previous?.middleName == current?.middleName
      && previous?.firstName == current?.firstName
      && previous?.lastName == current?.lastName
      && previous?.dateOfBirth == current?.dateOfBirth;
  }

  private getStudentFilterTitle(selectedPageScope: PageScope): string {
    switch(selectedPageScope){
      case PageScope.State: {
        return 'Statewide Search';
      }
      case PageScope.District: {
        return 'District Search';
      }
      case PageScope.School: {
        return 'School Search';
      }
    }
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    console.debug('onContextFilterSearchClicked:', selectedScopeContextFilterData);
    this._scopeContextFilterSelectedDataSubject$.next(selectedScopeContextFilterData);
  }

  protected onStudentFilterSearchClicked(studentSearchTerms: StudentModel.StudentSearchTerms): void {
    console.debug('onStudentFilterSearchClicked -> studentSearchTerms:', studentSearchTerms);
    this._studentSearchTermsSubject$.next(studentSearchTerms);
  }

  protected onStudentFilterResetClicked(): void {
    this._studentSearchTermsSubject$.next({});
  }
}
