import { Injectable } from '@angular/core';
import { AdditionalInfoModel } from './additional-info.model';
import { Observable, of } from 'rxjs';
export { AdditionalInfoModel } from './additional-info.model';

@Injectable({
  providedIn: 'root'
})
export class AdditionalInfoService {
  public static SelectedFieldState = 'selectedFieldState'; 

  public saveFieldStateToLocalStorage(categories: AdditionalInfoModel.Category[]): void {
    localStorage.setItem(AdditionalInfoService.SelectedFieldState, JSON.stringify(categories));
  }

  public saveFieldStateToSessionStorage(categories: AdditionalInfoModel.Category[]): void {
    sessionStorage.setItem(AdditionalInfoService.SelectedFieldState, JSON.stringify(categories));
  }
  

  public getFieldState(): Observable<AdditionalInfoModel.Category[]> {
    const sessionStorageData = this.getSessionFieldState();
    if(sessionStorageData){
      return sessionStorageData;
    }

    const localStorageData = this.getSavedFieldState();
    if (localStorageData) {
      return localStorageData;
    }
    
    return this.getDefaultFieldState();
  }

  public getDefaultFieldState(): Observable<AdditionalInfoModel.Category[]> {
    return of(this.defaultFilterCategories);
  }

  public getSessionFieldState(): Observable<AdditionalInfoModel.Category[]> | undefined {
    const data = sessionStorage.getItem(AdditionalInfoService.SelectedFieldState);
    if (data) {
      return of(JSON.parse(data) as AdditionalInfoModel.Category[]);
    }
    return undefined;
  }

  public getSavedFieldState(): Observable<AdditionalInfoModel.Category[]> | undefined {
    const data = localStorage.getItem(AdditionalInfoService.SelectedFieldState);
    if (data) {
      return of(JSON.parse(data) as AdditionalInfoModel.Category[]);
    }
    return undefined;
  }

  private defaultFilterCategories: AdditionalInfoModel.Category[] = [
    {
      name: 'Attendance',
      expanded: true,
      fields: [
        { name: 'Attendance Date', key: 'attendanceDate', selected: false },
        { name: 'Attendance Type', key: 'attendanceType', selected: false },
        { name: 'Label', key: 'label', selected: false } ]
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        { name: 'Local Class Number', key: 'localClassNumber', selected: false }, 
        { name: 'Course Code', key: 'courseCode', selected: false }, 
        { name: 'Class Type', key: 'classType', selected: false }, 
        { name: 'Voc. Outside IP', key: 'vocOutsideIP', selected: false }, 
        { name: 'Enrolment Period Start Date', key: 'enrolmentPeriodStartDate', selected: false }, 
        { name: 'Enrolment Period End Date', key: 'enrolmentPeriodEndDate', selected: false }, 
        { name: 'Assignment Start Dates', key: 'assignmentStartDates', selected: false }, 
        { name: 'Assignment End Dates', key: 'assignmentEndDates', selected: false }, 
        { name: 'Class Start Dates', key: 'classStartDates', selected: false }]
    },
    {
      name: 'Classification',
      expanded: true,
      fields: [
        { name: 'Classification', key: 'classification', selected: false }, 
        { name: 'Begin Date', key: 'beginDate', selected: false }, 
        { name: 'End Date', key: 'endDate', selected: false }]
    },
    {
      name: 'Club Membership',
      expanded: true,
      fields: [
        { name: 'Club ID', key: 'clubID', selected: false },
        { name: 'Begin Date', key: 'beginDate', selected: false }, 
        { name: 'End Date', key: 'endDate', selected: false }]
    },
    {
      name: 'Disciplinary Actions',
      expanded: true,
      fields: [
        { name: 'Enrollment Period Start', key: 'enrollmentPeriodStart', selected: false }, 
        { name: 'Enrollment Period End', key: 'enrollmentPeriodEnd', selected: false }, 
        { name: 'Disciplinary Period Begin', key: 'disciplinaryPeriodBegin', selected: false }, 
        { name: 'Disciplinary Period End', key: 'disciplinaryPeriodEnd', selected: false }, 
        { name: 'Disciplinary Type', key: 'disciplinaryType', selected: false }, 
        { name: 'Disciplinary Reason', key: 'disciplinaryReason', selected: false }, 
        { name: 'Sped', key: 'sped', selected: false }, 
        { name: 'Zero Tolerance', key: 'zeroTolerance', selected: false }]
    }
  ];
}
