<div class="container">
    <div class="container" style="max-width:100%">
        @if (viewModel) {
            <div class="header container">
                <h3>Student Details</h3>
                <div class="controls">
                    <div class="page-size">
                        <span>Results Per Page</span>
                        <select name="pageSize" [(ngModel)]="paginator.pageSize" (change)="onPageSizeChange($event)"> 
                            <option *ngFor="let pageSize of paginator.pageSizeOptions" [value]="pageSize">{{pageSize}}</option>
                        </select>
                    </div>
                    <div class="buttons">
                        <app-additional-info (fieldSelected)="onAdditionalInfoFieldSelected($event)"/>
                        <button tdoe-button>Filter Data</button>
                        <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button mat-menu-item>CSV</button>
                            <button mat-menu-item>PDF</button>
                            <button mat-menu-item>Excel</button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="table-title">
                <span>Student Information</span>
            </div>
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Name Last, First, Middle </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.name}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="ssid">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> SSID </mat-header-cell>
                    <mat-cell *matCellDef="let student" > {{student.ssid | ssidFormat}} </mat-cell>
                </ng-container>
            
                <!-- <ng-container matColumnDef="serviceType">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Service Type </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.serviceType}} </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="dateOfBirth">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Birth Date </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.dateOfBirth | date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="grade">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Grade </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.grade}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="enrollmentStartDate">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Enrollment Start Date </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.enrollmentStartDate | date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="enrollmentEndDate">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Enrollment End Date </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.enrollmentEndDate | date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="code">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Code </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.code}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="district" *ngIf="viewModel.displayedColumns.includes('district')">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> District </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.district}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="school" *ngIf="viewModel.displayedColumns.includes('school')">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> School </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student.school}} </mat-cell>
                </ng-container>

                @if (dynamicColumns.length > 0) {
                    @for (col of dynamicColumns;track col)
                    {
                        <ng-container [matColumnDef]="col.key">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> {{col.name}} </mat-header-cell>
                            <mat-cell *matCellDef="let student"> {{student[col.key]}} </mat-cell>
                        </ng-container>
                    }
                }

                <mat-header-row *matHeaderRowDef="viewModel.displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let student; columns: viewModel.displayedColumns" (click)="onRowClicked(student)"></mat-row>
                
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching applied filters</td>
                </tr>
            </mat-table>

            <mat-paginator
                #paginator
                pageSize="50"
                [pageSizeOptions]="[25, 50, 100, 500, 1000]"
                showFirstLastButtons>
            </mat-paginator>
        }
        @else {
            <h1>Skeleton</h1>
        }
    </div>
</div>