import { Component, OnInit } from '@angular/core';
import { DataLookupFilterModel, SearchTerms } from '../shared/school-affiliation-filter/school-affiliation-filter.model';
import { SchoolAffiliationFilterComponent } from '../shared/school-affiliation-filter/school-affiliation-filter.component';
import { StudentTableComponent } from '../shared/student-table/student-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { StudentModel, StudentService } from 'app/services/student/student.service';
import { TdoeDsModule } from '@tdoe/design-system';

@Component({
  selector: 'app-data-lookup-class',
  templateUrl: './data-lookup-class.component.html',
  styleUrl: './data-lookup-class.component.scss',
  standalone: true,
  imports: [
    SchoolAffiliationFilterComponent, 
    StudentTableComponent,
    TdoeDsModule
  ]
})
export class DataLookupClassComponent implements OnInit {

  protected studentDataSource = new MatTableDataSource<StudentModel.Student>();
  protected year: number | undefined;
  protected districtId: number | undefined;
  protected schoolId: number | undefined;

  public constructor(private studentService: StudentService){
    this.year = new Date().getFullYear();
  }

  public ngOnInit(): void {
    //Clark: set the context based on the user's profile
    this.districtId = 10;
    //this.schoolId = 5;
  }

  public onApplyClick(searchTerms: SearchTerms): void {
    this.schoolId = searchTerms.schoolId;
    this.year = searchTerms.year;
    this.fetchStudents(searchTerms);
  }

  private fetchStudents(searchTerms: DataLookupFilterModel.SearchTerms): void {
    if(!this.isParametersValid(searchTerms)){
      this.studentDataSource.data = [];
      return;
    }
    this.studentService.getStudentsBySearchTerms(searchTerms).subscribe(results => {
      this.studentDataSource.data = results;
    });
  }

  public isParametersValid (searchTerms: DataLookupFilterModel.SearchTerms) : boolean {
    // year and schoolId are required to make a valid search
    return !!searchTerms.year && !!searchTerms.schoolId;
  }
}
