import { NavItem } from '@tdoe/design-system';

export  class TypeGuards {
  // This one could be moved into tdoe-ds
  public static isNavItem(obj: unknown): obj is NavItem {
      return typeof obj === 'object' && obj !== null &&
        'id' in obj && typeof (obj as NavItem).id === 'string' &&
        'text' in obj && typeof (obj as NavItem).text === 'string';
  }
}

