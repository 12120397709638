<div class="container">
    <div class="container">
        <ng-container *ngIf="viewModel$ | async as viewModel">
            <div class="section">
                <div class="label">Average Daily Membership (ADM)</div>
                <div class="card rounded-corners">
                    <div class="number">{{ viewModel.year0MembershipSummary?.baseADM }}</div>
                    <div class="label">Base ADM</div>
                </div>
            </div>
            <div class="section">
            <div class="label"></div>
                <div class="card rounded-corners">
                    <div class="number">{{ viewModel.year0MembershipSummary?.membership }}</div>
                    <div class="label">Membership</div>
                </div>
            </div>

            <div class="section">
                <div class="label">Year over Year Base ADM</div>
                <div class="card2 rounded-corners">
                    <div class="card">
                        <div class="number">{{ viewModel.year1MembershipSummary?.baseADM }}</div>
                        <div class="label">{{ viewModel.year1MembershipSummary?.year }}</div>
                    </div>
                    <div class="card">
                        <div class="number">{{ viewModel.year2MembershipSummary?.baseADM }}</div>
                        <div class="label">{{ viewModel.year2MembershipSummary?.year }}</div>
                    </div>
                </div>
            </div>
            
            <div class="section">
                <div class="label">Year over Year Membership</div>
                <div class="card2 rounded-corners">
                    <div class="card">
                        <div class="number">{{ viewModel.year1MembershipSummary?.membership }}</div>
                        <div class="label">{{ viewModel.year1MembershipSummary?.year }}</div>
                    </div>
                    <div class="card">
                        <div class="number">{{ viewModel.year2MembershipSummary?.membership }}</div>
                        <div class="label">{{ viewModel.year2MembershipSummary?.year }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
