import { Component } from '@angular/core';
import { ScopeContextFilterModel } from '../shared/scope-context-filter/scope-context-filter.model';
import { ErrorCategoryCardsComponent } from './error-category-cards/error-category-cards.component';
import { CommonModule } from '@angular/common';
import { distinctUntilChanged,  filter,  map, startWith, Subject, switchMap } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { AverageDailyMembershipComponent } from './average-daily-membership/average-daily-membership.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { ScopeContextFilterComponent } from '../shared/scope-context-filter/scope-context-filter.component';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ScopeContextFilterComponent,
    ErrorCategoryCardsComponent,
    AverageDailyMembershipComponent,
    DemographicsComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  private _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();

  protected viewModel$ = this._userProfileService.getUserProfile().pipe(
    filter(userProfile => !!userProfile),
    switchMap(userProfile =>  this._scopeContextFilterSelectedDataSubject$.pipe(
      distinctUntilChanged((prev, curr) => (
        prev.year === curr.year
        && prev.districtId === curr.districtId
        && prev.schoolId === curr.schoolId
      )),
      map(searchTerms => ({
        userProfile,
        isSelectedDataValid: this.isContextDataValid(
          searchTerms,
          userProfile.pageScope),
        ...searchTerms!
      })),
      startWith({
        userProfile,
        isSelectedDataValid: true,
        pageScope: userProfile.pageScope,
        year: new Date().getFullYear(),
        schoolId: userProfile.pageScope === PageScope.School
          ? userProfile.scopeId!
          : undefined,
        districtId: userProfile.pageScope === PageScope.District
          ? userProfile.scopeId!
          : undefined
      })
    )),
    map(data => ({
      userPageScope: data.userProfile.pageScope,
      userScopeId: data.userProfile.scopeId,
      selectedPageScope: data.pageScope,
      selectedYear: data.year,
      selectedSchoolId: data.schoolId,
      selectedDistrictId: data.districtId,
      isSelectedDataValid: data.isSelectedDataValid,
      isUserPageScopeState: data.userProfile.pageScope === PageScope.State,
      isUserPageScopeDistrict: data.userProfile.pageScope === PageScope.District,
      isUserPageScopeSchool: data.userProfile.pageScope === PageScope.School,
    }))
  );

  public constructor (
    private _userProfileService: UserService) {
      //
  }

  private isContextDataValid(searchTerms: ScopeContextFilterModel.SelectedData, userPageScope: PageScope): boolean {
    switch(userPageScope){
      case PageScope.State: {
        return !! searchTerms.year;
      }
      case PageScope.District: 
      case PageScope.School: {
        return !! searchTerms.year && !! searchTerms.districtId;
      }
      default: {
        throw new Error('User does not have page scope.');
      }
    }
  }

  protected onSearchClicked(searchTerms: ScopeContextFilterModel.SelectedData): void {
    this._scopeContextFilterSelectedDataSubject$.next(searchTerms);
  }
}
