import { Component, EventEmitter, Output } from '@angular/core';
import * as StudentFilterModel from './student-filter.model';
import { TdoeDsModule } from '@tdoe/design-system';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

export * as StudentFilterModel from './student-filter.model';

@Component({
  selector: 'app-student-filter',
  templateUrl: './student-filter.component.html',
  styleUrl: './student-filter.component.scss',
  standalone: true,
  imports: [
    TdoeDsModule, 
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatSelectModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule
  ]
})
export class StudentFilterComponent {
  @Output() public searchClicked = new EventEmitter<StudentFilterModel.StudentSearchTerms>();
  @Output() public resetClicked = new EventEmitter<void>();

  protected studentSearchTerms: StudentFilterModel.StudentSearchTerms = {};

  protected formly = new FormGroup({});
  protected formFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: 'Last name',
            type: 'text',
            appearance: 'outline'
          }
        },
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: 'First name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-3'
        },
        {
          key: 'middleName',
          type: 'input',
          props: {
            label: 'Middle name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-3'
        },
        {
          key: 'ssid',
          type: 'input',
          props: {
            label: 'SSID',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-2'
        },
        {
          key: 'grade',
          type: 'select',
          props: {
            label: 'Grade',
            options: [
              { label: 'K', value: 'K' },
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' }
            ],
            appearance: 'outline',
          },
          className: 'filter-1'
        },
        {
          key: 'dateOfBirth',
          type: 'datepicker',
          props: {
            label: 'Birth date',
            appearance: 'outline'
          },
          className: 'filter-2'
        }
      ]
    }
  ];
 
  protected onSearchClick(): void {
    this.studentSearchTerms = StudentFilterComponent.setEmptyStringsToUndefined(this.studentSearchTerms);
    this.studentSearchTerms.ssid = this.studentSearchTerms.ssid?.replaceAll('-', '');
    this.searchClicked.emit(this.studentSearchTerms);
  }

  protected onResetClick(): void {
    this.studentSearchTerms = {};
    this.resetClicked.emit();
  }

  public static setEmptyStringsToUndefined(studentSearchTerms: StudentFilterModel.StudentSearchTerms): StudentFilterModel.StudentSearchTerms {
    const trimmedStudentSearchTerms = StudentFilterComponent.trimStrings(studentSearchTerms);
    return Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(trimmedStudentSearchTerms).filter(([_, value]) => value !== '' && value !== undefined)
    ) as StudentFilterModel.StudentSearchTerms;
  }

  public static trimStrings(terms: StudentFilterModel.StudentSearchTerms): StudentFilterModel.StudentSearchTerms {
    return Object.fromEntries(
        Object.entries(terms).map(([key, value]) => [
            key,
            typeof value === 'string' ? value.trim() : value
        ])
    ) as StudentFilterModel.StudentSearchTerms;
  }
}
