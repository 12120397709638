import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule, DatePipe } from '@angular/common';
import { SsidFormatPipe } from 'app/pipes/ssid-format/ssid-format.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { TdoeDsModule } from '@tdoe/design-system';
import { StaffModel } from 'app/services/staff/staff.model';


interface ScreenSize {
  height: number,
  width: number
}

interface Column {
  name: string,
  screenWidthThreshold: number
}

@Component({
  selector: 'app-staff-table',
  templateUrl: './staff-table.component.html',
  styleUrl: './staff-table.component.scss',
  standalone: true,
  imports: [
    CommonModule, 
    DatePipe, 
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule, 
    SsidFormatPipe,
    TdoeDsModule
  ]
})
export class StaffTableComponent implements  AfterViewInit  {

  @Input()
  public dataSource!: MatTableDataSource<StaffModel.StaffMember>;

  @ViewChild(MatPaginator) 
  public paginator!: MatPaginator;

  private _columns: Column[] = [
    { name: 'name', screenWidthThreshold: 0}, 
    { name: 'teacherLicenseNumber', screenWidthThreshold: 0}, 
    { name: 'licensureCheck', screenWidthThreshold: 0}, 
    { name: 'district', screenWidthThreshold:  500}, 
    { name: 'school', screenWidthThreshold:  0}
  ];

  public constructor(
    private changeDetectorRef: ChangeDetectorRef) {
    console.log('StaffTableComponent constructor');
  }

  public onPageSizeChange($event: Event) : void {
    const pageSizeSelect = $event.target as HTMLSelectElement;
    this.paginator._changePageSize(Number.parseInt(pageSizeSelect.value));
  }

  public ngAfterViewInit(): void {
    console.log('StudentTableComponent ngAfterViewInit');
    this.dataSource.paginator = this.paginator;
    this.changeDetectorRef.detectChanges();
  }

  private getScreenSize(): ScreenSize {
    return {
      height: window.innerHeight,
      width: window.innerWidth
    };
  }

  public getDisplayedColumns(): string[] {
    const screenSize = this.getScreenSize();
    return this._columns
      .filter(column => screenSize.width > column.screenWidthThreshold || column.screenWidthThreshold === 0 )
      .map(column => column.name);
  }


}