<div class="content-card">
    <div class="content-header">
      <span class="content-subject">English & Language Arts</span>
      <span class="content-grade">PK</span>
    </div>
    <div class="content-body">
      <h3 class="content-title">
        <a href="https://duckduckgo.com/?q=cats+wearing+fancy+hats&iar=images&iax=images&ia=images" target="_blank">
          Pre-K Resources: Sounds First Activities- Snatch a Sound Version a (WCS)
        </a>
      </h3>
      <p class="content-description">
        In this section, you will find Pre-K videos developed by the Model Districts that can support the implementation of the sounds first activities provided in the...
      </p>
    </div>
    <div class="content-categories">
      <span class="content-tag educational-content">educational content</span>
      <span class="content-tag video"><mat-icon svgIcon="video"/>video</span>
      
    </div>
    <hr/>
    <div class="content-footer">
        <div class="content-footer-title">Collection</div>
        <div class="content-footer-content">
            <a href="https://duckduckgo.com/?q=dogs+wearing+birthday+hats&iar=images&iax=images&ia=images" target="_blank">Collection Title</a>
        </div>
    </div>
  </div>
