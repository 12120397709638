
<div class="person-search-terms">
    <form [formGroup]="formly">
        <formly-form [form]="formly" [model]="studentSearchTerms" [fields]="formFields"></formly-form>
        <div class="action-row">
            <button tdoe-button type="secondary" (click)="onResetClick()">Reset</button>
            <button tdoe-button (click)="onSearchClick()">Search</button>
        </div>
    </form>
</div>
