import { Component, EventEmitter, Output } from '@angular/core';
import { StaffFilterModel } from './staff-filter.model';
import { TdoeDsModule } from '@tdoe/design-system';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObjectUtilities } from '../../../../utilities/object-utilities/object-utilities';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';

export { StaffFilterModel } from './staff-filter.model';

@Component({
  selector: 'app-staff-filter',
  templateUrl: './staff-filter.component.html',
  styleUrl: './staff-filter.component.scss',
  standalone: true,
  imports: [
    TdoeDsModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatSelectModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule
  ]
})
export class StaffFilterComponent {

  @Output() public searchClicked = new EventEmitter<StaffFilterModel.StaffSearchTerms>();

  protected personSearchTerms: StaffFilterModel.StaffSearchTerms;

  protected formGroup = new FormGroup({});
  protected formlyFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'lastName',
          type: 'input',
          props: {
            label: 'Last name',
            type: 'text',
            appearance: 'outline'
          }
        },
        {
          key: 'firstName',
          type: 'input',
          props: {
            label: 'First name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-3'
        },
        {
          key: 'middleName',
          type: 'input',
          props: {
            label: 'Middle name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-3'
        },
        {
          key: 'teacherLicenseNumber',
          type: 'input',
          props: {
            label: 'Teacher license no.',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-2'
        },
        {
          key: 'dateOfBirth',
          type: 'datepicker',
          props: {
            label: 'Date of birth',
            appearance: 'outline'
          },
          className: 'filter-2'
        }
      ]
    }
  ];
  
  public constructor () {
    this.personSearchTerms = {};
  }

  protected onResetClick(): void {
    this.personSearchTerms = {};
    this.formGroup.reset();
  }

  protected onSearchClick(): void {
    this.searchClicked.emit(ObjectUtilities.setEmptyStringsToUndefined(ObjectUtilities.trimStrings(this.personSearchTerms)));
  }
}
