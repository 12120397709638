import { Component } from '@angular/core';

@Component({
  selector: 'app-hero-welcome',
  templateUrl: './hero-welcome.component.html',
  styleUrls: ['./hero-welcome.component.scss']
})
export class HeroWelcomeComponent {
  public onLoginToSwordClick(): void {
    throw new Error('Method not implemented.');
  }
}
