import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DistrictScopeContextFilterComponent } from './district-scope-context-filter/district-scope-context-filter.component';
import { SchoolScopeContextFilterComponent } from './school-scope-context-filter/school-scope-context-filter.component';
import { StateScopeContextFilterComponent } from './state-scope-context-filter/state-scope-context-filter.component';
import { ScopeContextFilterModel } from './scope-context-filter.model';
import { BehaviorSubject, combineLatest, debounceTime, map, tap } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

interface viewModel {
    selectedYear?: number,
    selectedDistrictId?: number,
    selectedSchoolId?: number,
    userScopeId?: number,
    isUserPageScopeState: boolean,
    isUserPageScopeDistrict: boolean,
    isUserPageScopeSchool: boolean
}

@Component({
  selector: 'app-scope-context-filter',
  standalone: true,
  imports: [
    CommonModule,     
    StateScopeContextFilterComponent, 
    DistrictScopeContextFilterComponent, 
    SchoolScopeContextFilterComponent,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './scope-context-filter.component.html',
  styleUrl: './scope-context-filter.component.scss'
})
export class ScopeContextFilterComponent {

  @Input({required: true})
  public set userPageScope(userPageScope: PageScope) {
    this._userPageScopeSubject$.next(userPageScope);
  }

  @Input({required: true})
  public set userScopeId(userScopeId: number | undefined) {
    this._userScopeIdSubject$.next(userScopeId);
  }

  @Input()
  public set selectedYear(selectedYear: number | undefined) {
    if(selectedYear){
      this._selectedYearSubject$.next(selectedYear);
    }
  }

  @Input()
  public set selectedDistrictId(selectedDistrictId: number | undefined) {
    if(selectedDistrictId){
      this._selectedDistrictIdSubject$.next(selectedDistrictId);
    }
  }

  @Input()
  public set selectedSchoolId(selectedSchoolId: number | undefined) {
    if(selectedSchoolId){
      this._selectedSchoolIdSubject$.next(selectedSchoolId);
    }
  }

  @Output()
  public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData> ();

  private _selectedYearSubject$ = new BehaviorSubject<number>(new Date().getFullYear()); 
  private _selectedDistrictIdSubject$ = new BehaviorSubject<number | undefined>(undefined); 
  private _selectedSchoolIdSubject$ = new BehaviorSubject<number | undefined>(undefined); 
  private _userPageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined); 
  private _userScopeIdSubject$ = new BehaviorSubject<number | undefined>(undefined); 

  protected viewModel$ = combineLatest([
    this._selectedYearSubject$,
    this._selectedDistrictIdSubject$,
    this._selectedSchoolIdSubject$,
    this._userPageScopeSubject$,
    this._userScopeIdSubject$,
  ]).pipe(
    debounceTime(300),
    map(([year, districtId, schoolId, userPageScope, scopeId]) => ({year, districtId, schoolId, userPageScope, scopeId})),
    map(data => ({
      selectedYear: data.year,
      selectedDistrictId: data.districtId,
      selectedSchoolId: data.schoolId,
      userScopeId: data.scopeId,
      isUserPageScopeState: data.userPageScope === PageScope.State,
      isUserPageScopeDistrict: data.userPageScope === PageScope.District,
      isUserPageScopeSchool: data.userPageScope === PageScope.School
    } as viewModel))
  );
  
  protected onSearchClicked(selectedData: ScopeContextFilterModel.SelectedData): void {
    this.searchClick.emit(selectedData);
  }
}
