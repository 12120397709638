import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ErrorCategory } from 'app/components/error-log/enums/error-category';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { ErrorSubCategory } from '../enums/error-subcategory';
import { PageScope } from 'app/enums/page-scope';
import { ErrorLogServiceModel } from 'app/services/error-log/error-log.model';
import { ErrorTableComponent } from '../shared/error-table/error-table.component';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-error-log-school',
  standalone: true,
  imports: [CommonModule, MatTabsModule, ErrorTableComponent],
  templateUrl: './error-log-school.component.html',
  styleUrl: './error-log-school.component.scss'
})
export class ErrorLogSchoolComponent {

  protected subCategoryErrorLabels$ = this._userProfileService.getUserProfile().pipe(
      /** For PageScope.State scopeId is not set until a district is selected
       * so the following filter cancels stream execution if scope id is falsy.
      */ 
      filter(userProfile => !!(userProfile && userProfile?.scopeId)),
      mergeMap(userProfile => this.getCounts(userProfile!.pageScope, userProfile!.scopeId!).pipe(
        map(categoryErrorCounts => {
          const category = categoryErrorCounts.find(category => category.name === ErrorCategory.school);
          return category?.subCategoryErrorCounts ?? [];
        }),
        map(subCategoryErrorCounts => {
          subCategoryErrorCounts.push({ 
            name: ErrorSubCategory.total, 
            count: subCategoryErrorCounts.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
          });
          return subCategoryErrorCounts;
        }),
        map(subCategoryErrorCounts => ({
          calender: `Calendar Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.calendar)?.count ?? 0})`,
          warning: `Warnings (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.warning)?.count ?? 0})`,
          total: `All (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.total)?.count ?? 0})`
        }))
      ))
  );

  public constructor(
    private _userProfileService: UserService,
    private _errorLogService: ErrorLogService) {
      //
    }

    private getCounts(pageScope: PageScope, scopeId: number): Observable<ErrorLogServiceModel.CategoryErrorCount[]>{
      switch(pageScope){
        case PageScope.State:
        case PageScope.District: {
          return this._errorLogService.getDistrictCounts(scopeId);
        }
        case PageScope.School: {
          return this._errorLogService.getSchoolCounts(scopeId);
        }
      }
    }
}
