import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScopeContextFilterModel } from '../scope-context-filter.model';
import { StudentService } from 'app/services/student/student.service';
import { SchoolModel, SchoolService } from 'app/services/school/school.service';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { TdoeDsModule } from '@tdoe/design-system';
import { BehaviorSubject, combineLatest, map, mergeMap, Observable, of, tap } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';

 interface viewModel {
  selectedYear: number;
  selectedDistrictId: number | undefined;
  selectedSchoolId: number | undefined;
  /** is the state of the component valid so that when the search button is clicked a value can be emitted */
  isValid: boolean;
  years: string[];
  districts: SchoolModel.District[];
  schools: SchoolModel.School[] | never[];
}

@Component({
  selector: 'app-district-scope-context-filter',
  templateUrl: './district-scope-context-filter.component.html',
  styleUrls: ['./district-scope-context-filter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatExpansionModule, 
    CommonModule,
    TdoeDsModule
  ]
})
export class DistrictScopeContextFilterComponent {
    @Input()
    public set year(year: number | undefined) {
      if(year){
        this._selectedYearSubject.next(year);
      }
    }

    @Input({required: true})
    public set districtId(districtId: number) {
      this._selectedDistrictIdSubject.next(districtId);
    }

    @Input()
    public set schoolId(schoolId: number | undefined) {
      if(schoolId){
        this._selectedSchoolIdSubject.next(schoolId);
      }
    }
    
    @Output()
    public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData> ();

    private _selectedYearSubject = new BehaviorSubject<number>(new Date().getFullYear()); 
    private _selectedDistrictIdSubject = new BehaviorSubject<number | undefined>(undefined); 
    private _selectedSchoolIdSubject = new BehaviorSubject<number | undefined>(undefined); 

    protected viewModel$ : Observable<viewModel> = combineLatest([
      this._studentService.getAvailableYears(), 
      this._schoolService.getDistricts(),
      this._selectedDistrictIdSubject,
      this._selectedSchoolIdSubject,
      this._selectedYearSubject
    ]).pipe(
      map(([years, districts, selectedDistrictId, selectedSchoolId, selectedYear]) => ({years, districts, selectedDistrictId, selectedSchoolId, selectedYear})),
      mergeMap(data => this.getSchools(data.selectedDistrictId).pipe(
        map(schools => ({
          selectedYear: data.selectedYear,
          selectedDistrictId: data.selectedDistrictId,
          selectedSchoolId: data.selectedSchoolId,
          years: data.years,
          districts: data.districts,
          schools,
          isValid: data.selectedYear !== undefined
              && data.selectedDistrictId !== undefined
        }))
      ))
    );

    protected searchTerms?: ScopeContextFilterModel.SelectedData;

    public constructor(
      private _studentService: StudentService,
      private _schoolService: SchoolService ){
      //
    }

    private getSchools(selectedDistrictId: number | undefined): Observable<SchoolModel.School[]> {
      return selectedDistrictId 
        ? this._schoolService.getSchools(selectedDistrictId)
        : of([]);
    }

    protected onSchoolChange(schoolId: number | undefined): void {
      this._selectedSchoolIdSubject.next(schoolId);
    }

    protected onYearChange(year: number): void {
      this._selectedYearSubject.next(year);
    }

    protected onSearchClick(viewModel: viewModel): void {
      if(viewModel.selectedDistrictId){
        this.searchClick.emit({ 
          year: viewModel.selectedYear, 
          districtId: viewModel.selectedDistrictId, 
          schoolId: viewModel.selectedSchoolId,
          pageScope: viewModel.selectedSchoolId
          ? PageScope.School 
          : PageScope.District
        });
      }
    }
}


