@if(viewModel$ | async; as viewModel){
   <app-scope-context-filter 
        [userPageScope]="viewModel.userPageScope" 
        [userScopeId]="viewModel.userScopeId"
        [selectedYear]="viewModel.selectedYear" 
        [selectedSchoolId]="viewModel.selectedSchoolId" 
        [selectedDistrictId]="viewModel.selectedDistrictId" 
        (searchClick)="onContextFilterSearchClicked($event)" />

    <div class="container student-filter">
        <div class="container ">
            <app-student-filter style="width:100%"
                (searchClicked)="onStudentFilterSearchClicked($event)"
                (resetClicked)="onStudentFilterResetClicked()"/>
        </div>
    </div>

    <app-student-table
        [selectedDistrictId]="viewModel.selectedDistrictId"
        [selectedSchoolId]="viewModel.selectedSchoolId"
        [dataSource]="viewModel.studentTableDataSource" />  
}
