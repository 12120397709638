import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hero-about',
  templateUrl: './hero-about.component.html',
  styleUrls: ['./hero-about.component.scss']
})
export class HeroAboutComponent {

  public constructor(
    private readonly router: Router){}
  public onMoreAboutSwordClick(): void {
    this.router.navigate(['/home/about']);
  }

}
