import { Component } from '@angular/core';

@Component({
  selector: 'app-grade-level',
  templateUrl: './grade-level.component.html',
  styleUrls: ['./grade-level.component.scss']
})
export class GradeLevelComponent {

}
