import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssidFormat',
  standalone: true
})
export class SsidFormatPipe implements PipeTransform {
  public transform(value: string): string {
    if (value.length > 8) {
      value = value.substring(0, 8);
    } else if (value.length < 8) {
      value = value.padStart(8, '0');
    } 

    return value.replace(/(\d{3})(\d{2})(\d{3})/, '$1-$2-$3');
  }
}
