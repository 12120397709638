import { CommonModule } from '@angular/common';
import { Component, Input, Output } from '@angular/core';
import { ErrorCategoryCardsModel } from './error-category-cards.model';
import { BehaviorSubject, combineLatest, debounceTime, filter,  map, mergeMap, Observable } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { ErrorLogModel, ErrorLogService } from 'app/services/error-log/error-log.service';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

interface viewModel {
  errorCategoryCards: ErrorCategoryCardsModel.ErrorCategoryCard[],
  pageScope: PageScope,
  scopeId?: number
}

@Component({
  selector: 'app-error-category-cards',
  standalone: true,
  imports: [CommonModule, RouterLink, MatIconModule],
  templateUrl: './error-category-cards.component.html',
  styleUrl: './error-category-cards.component.scss'
})
export class ErrorCategoryCardsComponent {

  @Input({required: true})
  public set pageScope(value: PageScope) {
    this._pageScopeSubject$.next(value);
  }

  @Input({required: true})
  public set year(value: number) {
    this._yearSubject$.next(value);
  }
  
  @Input()
  public set scopeId(value: number | undefined) {
    this._scopeIdSubject$.next(value);
  }


  private _scopeIdSubject$ = new BehaviorSubject<number | undefined>(undefined);
  
  private _pageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);
  
  private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);
  
  protected viewModel$: Observable<viewModel> =
    combineLatest([
      this._scopeIdSubject$,
      this._pageScopeSubject$.pipe(filter(pageScope => pageScope !== undefined)),
      this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([scopeId, pageScope, year]) => ({scopeId, pageScope, year})),
      mergeMap(data => this.getErrorCounts(data.pageScope!, data.scopeId).pipe(
        map(errorCounts =>({
          errorCategoryCards: this.transformCategoryErrorCounts(errorCounts),
          pageScope: data.pageScope!,
          scopeId: data.scopeId
        }))
      ))
    );

  public constructor (
    private _errorLogService: ErrorLogService
  ) {
    //
  }

  private transformCategoryErrorCounts(errorCounts: ErrorLogModel.CategoryErrorCount[]): ErrorCategoryCardsModel.ErrorCategoryCard[] {
    return Object.entries(ErrorLogService.convertToErrorCounts(errorCounts))
      .map(([key, value]) => ({
        name: StringUtilities.capitalizeFirstLetter(key),
        key,
        count: value.toString()
      }));
  }

  private getErrorCounts(pageScope: PageScope, scopeId?: number): Observable<ErrorLogModel.CategoryErrorCount[]>{
    switch(pageScope){
      case PageScope.State: {
        return this._errorLogService.getStateCounts();
      }
      case PageScope.District: {
        return this._errorLogService.getDistrictCounts(scopeId ?? 0);
      }
      case PageScope.School: {
       return this._errorLogService.getSchoolCounts(scopeId ?? 0);
      }
      default: {
        throw new Error('User has no page scope.');
      }
    }
  }
}
