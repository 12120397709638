export * as ErrorLogServiceModel from './error-log.model';

export interface ErrorCounts
{
    school: number,
    class: number,
    staff: number,
    student: number,
    total: number
}

export interface ErrorCount
{
    name: string,
    count: number
}

export interface CategoryErrorCount extends ErrorCount {
    subCategoryErrorCounts: ErrorCount[]
}

export interface ErrorItem {
    Id: number,
    calendarNumber: string,
    dataSourceEndpoint: string,
    errorDate: Date,
    severityLevel: string,
    errorReport: string
}