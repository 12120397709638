import { Component } from '@angular/core';

@Component({
  selector: 'app-program-participation',
  templateUrl: './program-participation.component.html',
  styleUrls: ['./program-participation.component.scss']
})
export class ProgramParticipationOneComponent {

}
