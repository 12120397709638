import { Component } from '@angular/core';

@Component({
  selector: 'app-data-lookup-school',
  templateUrl: './data-lookup-school.component.html',
  styleUrl: './data-lookup-school.component.scss'
})
export class DataLookupSchoolComponent {

}
