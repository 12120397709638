
<div class="person-search-terms">
    <form [formGroup]="formGroup" style="width:100%">
        <formly-form [form]="formGroup" [model]="personSearchTerms" [fields]="formlyFields"></formly-form>
        <div class="action-row">
            <button tdoe-button type="secondary" (click)="onResetClick()">Reset</button>
            <button tdoe-button (click)="onSearchClick()">Search</button>
        </div>
    </form>
</div>
