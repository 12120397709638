<ng-container *ngIf="navItems$ | async as navItems">
  <tdoe-layout appName="SWORD" 
    [headerNavItems]="navItems"
    headerAppLogo="/assets/images/SWORD.png"
    [enableSearch]="true"
    searchHint="Search"
    logo="/assets/images/tn-logo_96x96.png"
    (onNavClick)="onNavClick($event)"
    (onSearch)="onSearch($event)">
    <router-outlet page></router-outlet>
    <ng-container footer>
      <div class="tdoe-footer-container">
        <div class="tdoe-footer-column">
          <a [routerLink]="['/']">
            <img class="tdoe-brand" src="/assets/tdoe-ds/logo/tdoe.svg" alt="Tennessee Department of Education">
          </a>
        </div>
        <div class="tdoe-footer-column tdoe-flex-fill">
          <div class="versions">
            <div class="label-val">
              <div class="label">Client:</div>
              <div class="val">{{clientVersion}}</div>
            </div>
            <div class="label-val">
              <div class="label">API:</div>
              <div class="val">{{apiVersion}}</div>
            </div>
          </div>
        </div>
        <div class="tdoe-footer-column">
            <p class="mat-body-strong upsize">Need assistance?</p>
            <p class="mat-body">Having trouble using the site or need more information?</p>
            <tdoe-button size="large" text="Contact, help and FAQ" type="secondary"></tdoe-button>

          </div>
        <div class="tdoe-footer-copyright">
            <div class="tdoe-left-col">
                <a href="https://www.tn.gov/web-policies/privacy-statement.html" target="_blank">Privacy Policy</a>
            </div>
            <div class="tdoe-center-col">
                <a href="#">
                    <svg-icon src="/assets/images/instagram-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                </a>
                <a href="#">
                    <svg-icon src="/assets/images/x-twitter-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                </a>
                <a href="#">
                    <svg-icon src="/assets/images/facebook-f-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                </a>
                <a href="#">
                    <svg-icon src="/assets/images/flickr-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                </a>
                <a href="#">
                    <svg-icon src="/assets/images/linkedin-in-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                </a>
            </div>
            <div class="tdoe-right-col">
                <span>Copyright &copy; 2022 Tennessee Department of Education. All Rights Reserved.</span>
            </div>
        </div>
    </div>
    </ng-container>
  </tdoe-layout>
</ng-container>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true"><p style="color: white">{{loadingText}}</p></ngx-spinner>