import { Injectable } from '@angular/core';
import { UserRights } from 'app/constants/user-rights';
import { UserRoles } from 'app/constants/user-roles';
import { Observable, combineLatest, map, of } from 'rxjs';
import { UserModel } from './user.model';
import { PageScope } from 'app/enums/page-scope';
import { MsalService } from '@azure/msal-angular';
export { UserModel } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * This is where roles are mapped to rights
   */
  private _rolesRights: [string, string][] = [
    [UserRoles.StateAdmin, UserRights.CanViewState],
    [UserRoles.DistrictAdmin, UserRights.CanViewDistrict],
    [UserRoles.SchoolAdmin, UserRights.CanViewSchool]
  ];

  // ToDo: Get the actual role once the authorization is all wired up
  //private _userRole = UserRoles.SchoolAdmin; // Hardcoded for now
  // private _userRole = UserRoles.DistrictAdmin; // Hardcoded for now
  private _userRole = UserRoles.StateAdmin; // Hardcoded for now
  private _scopeId = 10;

  private _rights$: Observable<string[]> = of(
    this._rolesRights
      .filter(roleRight => roleRight[0] === this._userRole)
      .map(roleRight => roleRight[1])
  );

  private _pageScope$ = this._rights$.pipe(map(rights => {
    if (rights.includes(UserRights.CanViewState)) {
      return PageScope.State;
    }
    if (rights.includes(UserRights.CanViewDistrict)) {
      return PageScope.District;
    }
    if (rights.includes(UserRights.CanViewSchool)) {
      return PageScope.School;
    }
    throw new Error('User configuration error. Page scope is not assigned.');
  }));

  private _userProfile$ = combineLatest([this._rights$, this._pageScope$]).pipe(
    map(([rights, pageScope]) => ({ rights, pageScope })),
    map(data => ({
      pageScope: data.pageScope,
      scopeId: (data.pageScope === PageScope.State) ? undefined : this._scopeId,
      rights: data.rights
    }))
  );
  
  public constructor(private _msalService: MsalService){}

  public isUserAuthenticated(): boolean {
    const account = this._msalService.instance.getActiveAccount();
    return !!account;
  }

  public getUserProfile(): Observable<UserModel.UserProfile | undefined> {
    return this.isUserAuthenticated()? this._userProfile$ : of(undefined);
  }
}
