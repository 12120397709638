<div class="container">
    <div class="container">
        <ng-container *ngIf="viewModel$ | async as viewModel">
            <div class="filter">
                <label for="yearSelect">Select Year</label>
                <select id="yearSelect" [ngModel]="viewModel.selectedYear" (ngModelChange)="onYearChange($event)">
                    <option *ngFor="let year of viewModel.years" [ngValue]="+year">{{ year }}</option>
                </select>
            </div>
            <div class="filter">
                <label for="districtSelect">Select District</label>
                <select id="districtSelect" [ngModel]="viewModel.selectedDistrictId" disabled>
                    <option *ngFor="let district of viewModel.districts" [ngValue]="district.id">{{ district.name }}</option>
                </select>
            </div>
            <div class="filter">
                <label for="schoolSelect">Select School</label>
                <select id="schoolSelect" [ngModel]="viewModel.selectedSchoolId" (ngModelChange)="onSchoolChange($event)" [disabled]="!!!viewModel.selectedDistrictId">
                    <option [ngValue]="undefined" ></option>
                    <option *ngFor="let school of viewModel.schools" [ngValue]="school.id">{{ school.name }}</option>
                </select>
            </div>
            <div class="buttons">
                <button tdoe-button [disabled]="!viewModel.isValid" (click)="onSearchClick(viewModel)">Apply</button>
            </div>
        </ng-container>
    </div>
</div>
  
