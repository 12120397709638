import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AdditionalInfoComponent } from '../../shared/additional-info/additional-info.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AdditionalInfoModel, AdditionalInfoService } from 'app/services/additional-info/additional-info.service';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { StaffModel, StaffService } from 'app/services/staff/staff.service';
import { StaffMemberViewModel } from './staff-view.model';

@Component({
  selector: 'app-staff-view',
  standalone: true,
  imports: [
    AdditionalInfoComponent,
    MatExpansionModule,
    MatIconModule, 
    CommonModule
  ],
  templateUrl: './staff-view.component.html',
  styleUrl: './staff-view.component.scss'
})
export class StaffViewComponent implements OnInit {
  private additionalInfoService = inject(AdditionalInfoService);
  private destroyReference = inject(DestroyRef);
  private location = inject(Location);
  private route = inject(ActivatedRoute);
  private titleService = inject(Title);
  private staffService = inject(StaffService);

  private _categoriesSubject = new BehaviorSubject<AdditionalInfoModel.Category[]>([]);
  private _categories$: Observable<AdditionalInfoModel.Category[]> = this._categoriesSubject.asObservable();

  private _staffMemberId = this.route.snapshot.paramMap.get('id') ?? '0';
  private _staffMember$ = this.staffService.getStaffMember(this._staffMemberId);

      private _staffCategories$: Observable<StaffMemberViewModel.Category[]> =
      combineLatest([this._staffMember$, this._categories$])
        .pipe(
          map(([staff, categories]) => {
            return categories
              .filter(category => category.fields.some(field => field.selected))
              .map(category => ({
                ...category,
                fields: category.fields
                  .filter(field => field.selected)
                  .map(field => ({
                    ...field,
                    value: staff[field.key as keyof StaffModel.StaffMember]?.toString()
                  }))
              }));
          })
        );
  
  protected viewData$ = combineLatest([this._staffMember$, this._staffCategories$]).pipe(
    map(([staffMember, staffCategories]) => ({ staffMember, staffCategories })));

  public ngOnInit(): void {
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${this.route.snapshot.paramMap.get('name')}`);
        
    this.additionalInfoService.getFieldState()
      .pipe(takeUntilDestroyed(this.destroyReference))
      .subscribe(categories => this._categoriesSubject.next(categories));
  }

  public onFieldSelected(categories: AdditionalInfoModel.Category[]): void {
    this._categoriesSubject.next(categories);
  }

  public onBackToStaffClick(): void {
    this.location.back();
  }

}
