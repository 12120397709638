import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StudentEnrollmentClassification } from 'app/models';

@Component({
  selector: 'app-enrollment-classification',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    CommonModule
  ],
  templateUrl: './enrollment-classification.component.html',
  styleUrl: './enrollment-classification.component.scss'
})
export class EnrollmentClassificationComponent implements OnChanges {

  @Input({ required: true })
  public classifications?: StudentEnrollmentClassification[];

  protected datasource!: MatTableDataSource<StudentEnrollmentClassification>;

  protected displayedCols = ['classificationCode', 'classificationDateStart', 'classificationDateEnd', 'homelessResidence', 'homelessMckinnyVento', 'homelessUnaccompaniedYouth'];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['classifications'].currentValue) {
      this.loadTableData();
    }
  }

  private loadTableData(): void {
    this.datasource = new MatTableDataSource<StudentEnrollmentClassification>(this.classifications);
  }
}
