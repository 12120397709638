/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService, InactivityService, LoggingService, NavEvent } from '@tdoe/design-system';
import { HeaderNavItemsService } from './services/header-nav-items/header-nav-items.service';
import { registerMgtLoginComponent } from '@microsoft/mgt-components';
import { ConfigService } from './services/config/config.service';
import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  protected loadingText = 'Loading...';
  protected apiVersion = '0.0.0.0';
  protected clientVersion!: string;
  protected navItems$ = this._headerNavItemsService.getNavItems();

  public title = 'SWORD';

  public constructor(
    private readonly _router: Router,
    private _logger: LoggingService,
    private _headerNavItemsService: HeaderNavItemsService,
    private inactivityService: InactivityService,
    private configService: ConfigService,
    eventService: EventService) {

    registerMgtLoginComponent();

    eventService.onLoadingTextChange.subscribe({
      next: (text: string) => {
        if (text) {
          this.loadingText = text;
        }
      }
    });

  }

  public ngOnInit(): void {
    this.inactivityService.startMonitoring();

    this.configService.getApiVersion().subscribe({
      next: _ => this.apiVersion = _.version ?? '--',
      error: (err) => {
        this._logger.error(err);
      }
    });

    this.clientVersion = packageInfo.version;
  }

  public onSearch($event: string): void {
    throw new Error('Method not implemented.');
  }

  public onNavClick($event: NavEvent): void {
    if ($event.source.children) return;
    this._router.navigate([$event.source.id]);
  }
  public logOutCompleted($event: CustomEvent): void {
    this._logger.debug('logOutCompleted');
  }
  public logOutInitiated($event: CustomEvent): void {
    this._logger.debug('logOutInitiated');
  }
  public loginCompleted($event: CustomEvent): void {
    this._logger.debug('loginCompleted');
  }
  public loginInitiated($event: CustomEvent): void {
    this._logger.debug('loginInitiated');
  }
}
