

    <div class="header">
        <div class="controls">
            <div class="buttons">
                <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>CSV</button>
                    <button mat-menu-item>PDF</button>
                    <button mat-menu-item>Excel</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="table-title">
        <span>Error Data</span>
    </div>
    <table mat-table [dataSource]="dataSource" >
        
        <ng-container matColumnDef="calendarNumber">
            <th mat-header-cell *matHeaderCellDef> Calendar Number </th>
            <td mat-cell *matCellDef="let error"> {{error.calendarNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="dataSourceEndpoint">
            <th mat-header-cell *matHeaderCellDef> Data Source Endpoint </th>
            <td mat-cell *matCellDef="let error" > {{error.dataSourceEndpoint}} </td>
        </ng-container>

        <ng-container matColumnDef="errorDate">
            <th mat-header-cell *matHeaderCellDef> Error Date </th>
            <td mat-cell *matCellDef="let error"> {{error.errorDate}} </td>
        </ng-container>

        <ng-container matColumnDef="severityLevel">
            <th mat-header-cell *matHeaderCellDef> Severity Level </th>
            <td mat-cell *matCellDef="let error" [ngClass]="error.severityLevel.toLowerCase()"> {{error.severityLevel}} </td>
        </ng-container>

        <ng-container matColumnDef="errorReport">
            <th mat-header-cell *matHeaderCellDef> Error Report </th>
            <td mat-cell *matCellDef="let error"> {{error.errorReport}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>

    <mat-paginator
        #paginator
        [pageSize]="12"
        [pageSizeOptions]="[6, 12, 24]"
        [showFirstLastButtons]="false" >
    </mat-paginator>