import { TdoeAuthConfig } from '@tdoe/design-system/lib/tdoe-ds.config';
import { LogLevel } from '@tdoe/design-system';
import { InteractionType } from '@azure/msal-browser';

import packageInfo from '../../package.json';

const tdoeAuthConfig: TdoeAuthConfig = {
  authRequestScopes: [
    'api://5025f598-45b6-48ec-9f0a-c6a275c30dd4/access_as_user'
  ],
  clientId: '2a580ab7-20b8-43bd-b150-c4fbba143375',
  instance: 'https://login.microsoftonline.com',
  loginFailedRoute: '/login-failed',
  protectedResources: [
    {
      key: 'https://sword-server.qa.tnedu.gov/api',
      value: [
        'api://5025f598-45b6-48ec-9f0a-c6a275c30dd4/access_as_user'
      ]
    },
    {
      key: 'https://graph.microsoft.com/v1.0/me',
      value: [
        'user.read'
      ]
    }
  ],
  redirectUri: 'https://sword.qa.tnedu.gov',
  tenantId: '472445bd-2424-4e8f-b850-df7488e18b4a',
  logLevel: LogLevel.debug,
  takeOverConsoleLog: false,
  interactionType: InteractionType.Redirect,
  projectKey: '51A67943F438',
  authEnabled: true
};

export const environment = {
  production: false,
  apiBaseUrl: 'https://sword-server.qa.tnedu.gov/api/',
  authConfig: tdoeAuthConfig,
  clientVersion: packageInfo.version
};
