import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientConfiguration } from 'app/models/clientConfiguration';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public constructor(private http: HttpClient) { }

  public getApiVersion(): Observable<ClientConfiguration> {
    return this.http.get<ClientConfiguration>(`${environment.apiBaseUrl}config/version`);
  }

}
