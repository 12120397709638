<ng-container *ngIf="viewModel$ | async as viewModel; else skeleton">
    <app-state-scope-context-filter *ngIf="viewModel.isUserPageScopeState" [districtId]="viewModel.selectedDistrictId" [schoolId]="viewModel.selectedSchoolId" (searchClick)="onSearchClicked($event)"/>
    <app-district-scope-context-filter *ngIf="viewModel.isUserPageScopeDistrict" [districtId]="viewModel.userScopeId!" [schoolId]="viewModel.selectedSchoolId" (searchClick)="onSearchClicked($event)"/>
    <app-school-scope-context-filter *ngIf="viewModel.isUserPageScopeSchool" [schoolId]="viewModel.userScopeId!" (searchClick)="onSearchClicked($event)"/>
</ng-container>

<ng-template #skeleton>
    <div class="container">
        <div class="container">
            <ngx-skeleton-loader count="2" appearance="line"/>
        </div>
    </div>
</ng-template>