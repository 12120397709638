export * as SchoolModel from './school.model';

export interface School {
    id: number,
    name: string,
    districtId: number
}

export interface District {
    id: number,
    name: string
}