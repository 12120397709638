<div class="container">
  <div class="container">
    <div class="image-section">
      <img src="/assets/images/diploma-blue.png" alt="diploma">
    </div>
    <div class="text-section">

      <h1>Tennessee Department of Education</h1>
      <h1>Welcome to SWORD<span class="bold-orange">!</span></h1>
      <p>The Statewide Operations Reporting Dashboard (SWORD) is an essential tool for Tennessee's K-12 education
        system. It
        gathers important data such as student demographics, program participation, grades, enrollment, course
        enrollment,
        discipline, TCAP assessment data and teacher information. This information is crucial for monitoring and
        improving the
        quality of education in the state.</p>
    </div>
  </div>
</div>