import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataLookupFilterModel } from './school-affiliation-filter.model';
import { StudentService } from 'app/services/student/student.service';
import { SchoolModel, SchoolService } from 'app/services/school/school.service';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { TdoeDsModule } from '@tdoe/design-system';



@Component({
  selector: 'app-school-affiliation-filter',
  templateUrl: './school-affiliation-filter.component.html',
  styleUrls: ['./school-affiliation-filter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatExpansionModule, 
    CommonModule,
    TdoeDsModule
  ]
})
export class SchoolAffiliationFilterComponent implements OnChanges, OnInit {
    @Input()
    public year!: number;

    @Input()
    public schoolId?: number;

    @Input({ required: true })
    public districtId!: number;

    @Output()
    public applyClick = new EventEmitter<DataLookupFilterModel.SearchTerms> ();

    protected years: string[] = [];
    protected schools: SchoolModel.School[] = [];

    public constructor(
      private studentService: StudentService,
      private schoolService: SchoolService
    ){

    }

    private loadSchools(): void {
      //Clark: avoid using subscribe in the component class.
      this.schoolService.getSchools(this.districtId).subscribe({
        next: schools => {
          this.schools = schools;
          console.log(schools);
        },
        error: (err) => {
          console.log(err);
        }
      });
    }

    private loadYears(): void {
      this.studentService.getAvailableYears().subscribe({
        next: years => {
          this.years = years;
          this.year = parseInt(years[years.length - 1]);
        },
        error: (err) => {
          console.log(err);
        }
      });
    }

    public ngOnChanges(changes: SimpleChanges): void {
      console.log(changes);
      if (changes['districtId']?.currentValue) {
        this.loadSchools();
      }
    }

    public ngOnInit(): void {
      this.loadYears();
    }

    public onApplyClick(): void {
      this.applyClick.emit({ year: this.year, districtId: this.districtId, schoolId: this.schoolId });
    }

    public onResetClick(): void {
      this.year =  parseInt(this.years[0]);
      delete this.schoolId;
    }
}
