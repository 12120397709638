export * as StaffModel from './staff.model';

export interface StaffMember { 
  id?: number;
  readonly name?: string;
  nameFirst: string;
  nameLast: string;
  nameMiddle: string;
  teacherLicenseNumber: string;
  licensureCheck: string;
  district: string;
  school: string;
  dateOfBirth?: string;
}

export interface StaffSearchTerms {
  lastName?: string, 
  firstName?: string,
  middleName?: string,
  teacherLicenseNumber?: string,
  dateOfBirth?: Date
}