import { Component, DestroyRef, OnInit } from '@angular/core';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StudentModel, StudentService } from 'app/services/student/student.service';
import { AdditionalInfoModel, AdditionalInfoService } from 'app/services/additional-info/additional-info.service';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { StudentViewModel } from './student-view.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { EnrollmentClassificationComponent } from './enrollment-classification/enrollment-classification.component';
import { EnrollmentMembershipComponent } from './enrollment-membership/enrollment-membership.component';
import { EnrollmentHistoryComponent } from './enrollment-history/enrollment-history.component';

@Component({
  selector: 'app-student-view',
  standalone: true,
  imports: [
    AdditionalInfoComponent,
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    MatTabsModule,
    EnrollmentClassificationComponent,
    EnrollmentMembershipComponent,
    EnrollmentHistoryComponent
],
  templateUrl: './student-view.component.html',
  styleUrl: './student-view.component.scss'
})
export class StudentViewComponent implements OnInit {

  private _categoriesSubject = new BehaviorSubject<AdditionalInfoModel.Category[]>([]);
  private _categories$: Observable<AdditionalInfoModel.Category[]> = this._categoriesSubject.asObservable();

  private _studentId = this.route.snapshot.paramMap.get('id') ?? '0';
  private _student$ = this.studentService.getStudent(this._studentId);

  private _studentCategories$: Observable<StudentViewModel.Category[]> =
    combineLatest([this._student$, this._categories$])
      .pipe(
        map(([student, categories]) => {
          return categories
            .filter(category => category.fields.some(field => field.selected))
            .map(category => ({
              ...category,
              fields: category.fields
                .filter(field => field.selected)
                .map(field => ({
                  ...field,
                  value: student[field.key as keyof StudentModel.Student].toString()
                }))
            }));
        })
      );

  protected viewModel$ = combineLatest([this._student$, this._studentCategories$]).pipe(
    map(([student, studentCategories]) => ({ student, studentCategories })));

  public constructor (
    private route: ActivatedRoute,
    private titleService: Title,
    private studentService: StudentService,
    private additionalInfoService: AdditionalInfoService,
    private destroyReference: DestroyRef,
    private location: Location
  ){}

  public ngOnInit(): void {
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${this.route.snapshot.paramMap.get('name')}`);
    
    //Clark remove subscribe from class file
    this.additionalInfoService.getFieldState()
      .pipe(takeUntilDestroyed(this.destroyReference))
      .subscribe(categories => this._categoriesSubject.next(categories));
  }

  public onFieldSelected(categories: AdditionalInfoModel.Category[]): void {
    this._categoriesSubject.next(categories);
  }

  public onBackToStudentsClick(): void {
    this.location.back();
  }
}
