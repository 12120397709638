import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { MembershipModel } from './membership.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {
  public static StateMembershipSummaryUrl = environment.apiBaseUrl + 'Membership/StateMembershipSummary';
  public static DistrictMembershipSummaryUrl = environment.apiBaseUrl + 'Membership/DistrictMembershipSummary';
  public static SchoolMembershipSummaryUrl = environment.apiBaseUrl + 'Membership/SchoolMembershipSummary';
  public static MembershipByGradeSummaryUrl = environment.apiBaseUrl + 'Membership/MembershipByGradeSummary';

  public constructor(private http: HttpClient) { }

  public getStateMembershipSummary(year: number): Observable<MembershipModel.MembershipSummary[]> {
    return this.http.get<MembershipModel.MembershipSummary[]>(MembershipService.StateMembershipSummaryUrl, { params: { year } });
  }

  public getDistrictMembershipSummary(year: number, districtId: number): Observable<MembershipModel.MembershipSummary[]> {
    return this.http.get<MembershipModel.MembershipSummary[]>(MembershipService.DistrictMembershipSummaryUrl, { params: { year, districtId } });
  }

  public getSchoolMembershipSummary(year: number, schoolId: number, ): Observable<MembershipModel.MembershipSummary[]> {
    return this.http.get<MembershipModel.MembershipSummary[]>(MembershipService.SchoolMembershipSummaryUrl, { params: { year, schoolId } });
  }

  public getSchoolMembershipByGradeSummary(year: number, schoolId: number): Observable<MembershipModel.MembershipByGradeSummary[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('year', year)
      .append('schoolId', schoolId);

    return this.http.get<MembershipModel.MembershipByGradeSummary[]>(MembershipService.MembershipByGradeSummaryUrl, { params: httpParams});
  }

  public getDistrictMembershipByGradeSummary(year: number, districtId: number): Observable<MembershipModel.MembershipByGradeSummary[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('year', year)
      .append('districtId', districtId);

    return this.http.get<MembershipModel.MembershipByGradeSummary[]>(MembershipService.MembershipByGradeSummaryUrl, { params: httpParams});
  }

  public getStateMembershipByGradeSummary(year: number): Observable<MembershipModel.MembershipByGradeSummary[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('year', year);

    return this.http.get<MembershipModel.MembershipByGradeSummary[]>(MembershipService.MembershipByGradeSummaryUrl, { params: httpParams});
  }
}
