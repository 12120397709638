import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PageScope } from 'app/enums/page-scope';
import { MembershipModel } from 'app/services/membership/membership.model';
import { MembershipService } from 'app/services/membership/membership.service';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, mergeMap, Observable, tap } from 'rxjs';

interface viewModel {
  year0MembershipSummary?: MembershipModel.MembershipSummary,
  year1MembershipSummary?: MembershipModel.MembershipSummary,
  year2MembershipSummary?: MembershipModel.MembershipSummary
}

@Component({
  selector: 'app-average-daily-membership',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './average-daily-membership.component.html',
  styleUrl: './average-daily-membership.component.scss'
})
export class AverageDailyMembershipComponent {


  @Input({required: true})
  public set pageScope(value: PageScope) {
    this._pageScopeSubject$.next(value);
  }

  @Input({required: true})
  public set year(value: number) {
    this._yearSubject$.next(value);
  }

  @Input()
  public set scopeId(value: number | undefined) {
    this._scopeIdSubject$.next(value);
  }

  private _scopeIdSubject$ = new BehaviorSubject<number | undefined>(undefined);
  
  private _pageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);
  
  private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);

  protected viewModel$: Observable<viewModel> =
    combineLatest([
      this._scopeIdSubject$,
      this._pageScopeSubject$.pipe(filter(pageScope => pageScope !== undefined)),
      this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([scopeId, pageScope, year]) => ({scopeId, pageScope, year})),
      mergeMap(data => this.MembershipSummaries(data.pageScope!, data.year!, data.scopeId).pipe(
        map(membershipSummaries =>({
          year0MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year!),
          year1MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year! - 1),
          year2MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year! - 2)
        }))
      ))
    );

  public constructor(private _membershipService: MembershipService){}

  private MembershipSummaries(pageScope: PageScope, year: number, scopeId?: number): Observable<MembershipModel.MembershipSummary[]>{
    switch(pageScope){
      case PageScope.State: {
        return this._membershipService.getStateMembershipSummary(year);
      }
      case PageScope.District: {
        return this._membershipService.getDistrictMembershipSummary(year, scopeId ?? 0);
      }
      case PageScope.School: {
       return this._membershipService.getSchoolMembershipSummary(year, scopeId ?? 0);
      }
      default: {
        throw new Error('User has no page scope.');
      }
    }
  }
}
