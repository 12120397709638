export * as ObjectUtilities from './object-utilities';

export function setEmptyStringsToUndefined<T extends object>(obj: T): T {
  if(Array.isArray(obj)){
    throw new Error('Input cannot be an Array.');
  }

  const updatedObj: T = { ...obj };

  for (const key in updatedObj) {
      if (Object.prototype.hasOwnProperty.call(updatedObj, key) 
          && typeof updatedObj[key] === 'string' 
          && updatedObj[key] === '') {
          delete updatedObj[key];
      }
  }

  return updatedObj;
}

export function trimStrings<T extends object>(obj: T): T {
  const updatedObj: T = { ...obj };
  
  for (const key in updatedObj) {
      const updatedTerm = Object.prototype.hasOwnProperty.call(updatedObj, key) ? updatedObj[key] : undefined;
      if (updatedTerm && typeof updatedTerm === 'string') {
          (updatedObj[key] as string) = updatedTerm.trim();
      }
  }

  return updatedObj;
}

export function sum (theObject: object): number {
    
  return Object.values(theObject).reduce((previousValue, currentValue) => {
      if ( typeof currentValue === 'number' ){
          return currentValue + previousValue;
      }
      return previousValue;
  },0);
}
