<div class="container">
    <div class="container">
        <ng-container *ngFor="let tab of errorCategoryTabs$ | async as errorCategoryTabs">
        <div class="tab" [ngClass]="{ 'selected': tab.selected }" (click)="onTabClick(tab)">
            <div class="count">{{tab.count}}</div>
            <div class="name">{{tab.name}} Errors</div>
        </div>
    </ng-container>
    </div>
</div>
