<div class="container">
    <div class="container">
        <ng-container *ngIf="viewModel$ | async as viewModel">
            <h2>Demographics</h2>
            <app-gender [pageScope]="viewModel.pageScope"
                [scopeId]="viewModel.scopeId"
                [year]="viewModel.year"
                class="chart-component"/>
            <app-race [pageScope]="viewModel.pageScope"
                [scopeId]="viewModel.scopeId"
                [year]="viewModel.year"
                class="chart-component"/>
            <app-adm-by-grade [pageScope]="viewModel.pageScope"
                [scopeId]="viewModel.scopeId"
                [year]="viewModel.year"
                class="chart-component"/>
        </ng-container>
    </div>
</div>
