<div class="container error-log" *ngIf="subCategoryErrorLabels$ | async as subCategoryErrorLabels">
    <div class="container">
        <h3>School Errors</h3>
        <mat-tab-group>
            <mat-tab [label]="subCategoryErrorLabels.calender">
                <app-error-table/>
            </mat-tab>
            <mat-tab [label]="subCategoryErrorLabels.warning">
                <app-error-table/>
            </mat-tab>
            <mat-tab [label]="subCategoryErrorLabels.total">
                <app-error-table/>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
