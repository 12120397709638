export * as StringUtilities from './string-utilities';

export function FormatStringForUrl(input: string): string {
    // Remove all non-alphanumeric characters except spaces
    const alphanumericWithSpaces = input.trim().replace(/[^a-zA-Z0-9 ]/g, '');
  
    // Replace consecutive spaces with a single space
    const singleSpaced = alphanumericWithSpaces.replace(/\s+/g, ' ');
  
    // Replace spaces with dashes
    const result = singleSpaced.replace(/ /g, '-');
  
    return result;
  }

export function capitalizeFirstLetter(str: string): string {
  if (!str) return str;  // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1);
}